import DomainsAPI from '@equally-ai-front/common/src/api/domains';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import { isDomainNameValid } from '@equally-ai-front/common/src/lib/domains';
import {
  ProductTypes,
  SubTypes,
} from '@equally-ai-front/common/src/redux/user-products-slice/types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { extractHostname } from '../common/helpers';
import { setApiError, setApiSuccessMessage } from '../store/actions';

interface AddDomainFormActions {
  businessId: number | undefined;
}
const useAddDomainFormActions = (props: AddDomainFormActions) => {
  const { businessId } = props;
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const [domainsToAdd, setDomainsToAdd] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  // const isLoading = useSelector((state: any) => state.userProducts.loading);

  const addDomains = async (
    type: ProductTypes,
    subType: SubTypes | undefined,
    description: string | undefined,
    callback: any,
  ) => {
    let lastDomain = '';
    setIsLoading(true);
    const domains = domainsToAdd
      .filter((domain) => domain !== '')
      .map((dom) => extractHostname(dom.toLocaleLowerCase()));
    if (type !== 'service') {
      const invalidDomains = domains.filter((dom) => !isDomainNameValid(dom));

      if (invalidDomains.length > 0) {
        dispatch(setApiError('Invalid domains'));
        setIsLoading(false);
        return { isSuccess: false, error: 'Invalid domains', data: null };
      }
    }
    const { data, isSuccess, error } = await DomainsAPI.createDomains(
      domains,
      currentBusiness.id,
      type,
      subType,
      description,
    );
    if (isSuccess) {
      dispatch(setApiSuccessMessage('Domain successfully added!'));
    } else {
      dispatch(setApiError(error));
      setIsLoading(false);
      return { isSuccess: false, error, data: null };
    }
    setIsLoading(false);

    // for (const newDomain of domains) {
    //   // @ts-ignore
    //   dataLayer.push({
    //     event: 'freeTrial',
    //     type: type,
    //     domain: newDomain,
    //   });
    // }

    if (type === 'flowy') {
      lastDomain = data?.developer[data?.widget.length - 1];

      for (const domain of data?.widget || []) {
        analytics.track('New Website Created', {
          domain_record_id: domain.id,
          url: domain.name,
          type: type,
          plan_id: domain.plan_id === 0 ? undefined : domain.plan_id,
          business_id: businessId,
        });
      }
    } else {
      lastDomain = data[data.length - 1];

      for (const domain of data || []) {
        analytics.track('New Website Created', {
          domain_record_id: domain.id,
          url: domain.name,
          type: type,
          plan_id: domain.plan_id === 0 ? undefined : domain.plan_id,
          business_id: businessId,
        });
      }
    }

    callback(lastDomain, type);
    setDomainsToAdd(['']);

    return { isSuccess, error, data };
  };

  const handleNewDomainChange = (value: string, index: number) => {
    const newSites = [...domainsToAdd];
    newSites[index] = value;

    if (index === domainsToAdd.length - 1 && value.length >= 3) {
      newSites.push('');
    }
    setDomainsToAdd(newSites);
  };

  return { handleNewDomainChange, addDomains, domainsToAdd, isLoading };
};

export default useAddDomainFormActions;
