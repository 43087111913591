import { scanRecommendationsActions } from '@equally-ai-front/common/src/redux/developer-slice';
// import { GetSavedGptSuggestionsArgType } from '@equally-ai-front/common/src/redux/developer-slice/types';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import {
  // DevtoolsSolution,
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Divider as MuiDivider } from '@mui/material';
import React, { SyntheticEvent, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getJiraSettings,
  getJiraTasks,
} from '@equally-ai-front/common/src/redux/business-slice/business';
import styled from 'styled-components';
// import { ScanReportItemDetails } from './scan-report-item-details';
import {
  AccordionState,
  useAccessibilityAccordion,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { getIssueGroupName } from '@equally-ai-front/common/src/lib/issues';
import { ScanReportLayout } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-layout';
import { ScanReportSection } from '@equally-ai/audit-ui/src/components/scan-report/scan-report-section';
import { ScanReportItemDetails } from './scan-report-item-details';
// import { ScanReportSectionItem } from './scan-report-section-item';

interface ScanReportProps {
  wcagReport: ReportData;
  issueType: IssueType;
  handleTabChange?: (evt: SyntheticEvent, newValue: number) => void;
  devDomain: DevDomain;
  successMessage: string;
  setSuccessMessage: (message: string) => void;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  hasJiraSettingsPermission?: boolean;
}

export const ScanReport = ({
  wcagReport,
  issueType,
  handleTabChange,
  devDomain,
  successMessage,
  setSuccessMessage,
  selectedIssuesId,
  setSelectedIssuesId,
  hasJiraSettingsPermission,
}: ScanReportProps) => {
  const dispatch = useDispatch();
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const { accordionState, setAccordionState, handleAccordionToggle } =
    useAccessibilityAccordion();
  const [jiraSettings, setJiraSettings] = useState({});

  const { currentBusiness } = useSelector((state: any) => state.business);
  const handleToggleAccordion = (accordionId: string) => {
    setAccordionState((prevState) => {
      const emptyState: AccordionState = {};
      const newState: AccordionState = Object.keys(prevState).reduce(
        (acc, key) => {
          acc[key] = false;
          return acc;
        },
        emptyState,
      );

      newState[accordionId] = !prevState[accordionId];
      return newState;
    });
  };
  // const resetGptStates = useCallback(() => {
  //   dispatch(scanRecommendationsActions.setSavedMagicSuggestion([]));
  //   dispatch(scanRecommendationsActions.setSavedCreativeSuggestion([]));
  //   dispatch(scanRecommendationsActions.setSavedGuideSuggestion([]));
  //   dispatch(scanRecommendationsActions.setSavedExplainSuggestion([]));
  // }, [dispatch]);

  const toggleSectionItem = useCallback(
    (
      id: string,
      // payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType,
    ) => {
      if (isScanReportItemVisible && activeItemId === id) {
        setIsScanReportItemVisible(false);
        // resetGptStates();
      } else {
        dispatch(scanRecommendationsActions.setProductTourIndex(9));
        // dispatch(
        //   scanRecommendationsActions.getSavedMagicSuggestion(
        //     payloadToGetSavedGptSuggestion,
        //   ),
        // );
        // dispatch(
        //   scanRecommendationsActions.getSavedCreativeSuggestion(
        //     payloadToGetSavedGptSuggestion,
        //   ),
        // );
        // dispatch(
        //   scanRecommendationsActions.getSavedGuideSuggestion(
        //     payloadToGetSavedGptSuggestion,
        //   ),
        // );
        // dispatch(
        //   scanRecommendationsActions.getSavedExplainSuggestion(
        //     payloadToGetSavedGptSuggestion,
        //   ),
        // );
        // resetGptStates();
        setIsScanReportItemVisible(true);
        setActiveItemId(id);
      }
    },
    [isScanReportItemVisible, activeItemId, /* resetGptStates, */ dispatch],
  );

  const proceedToNextStage = (evt: SyntheticEvent<Element, Event>) => {
    if (handleTabChange) {
      if (wcagReport.list.potential.length === 0) {
        handleTabChange(evt, 3);
      } else if (wcagReport.list.advanced.length === 0) {
        handleTabChange(evt, 2);
      } else if (wcagReport.list.basic.length === 0) {
        handleTabChange(evt, 1);
      }
    }
  };

  const fetchJiraSettings = useCallback(async () => {
    if (!currentBusiness.id) return;
    const { payload } = await dispatch(
      //@ts-ignore
      getJiraSettings({
        businessId: currentBusiness.id,
      }),
    );
    if (!payload) return;

    const { isSuccess, data } = payload;
    if (isSuccess) {
      setJiraSettings(data);
    }
  }, [dispatch, currentBusiness]);

  const fetchJiraTasks = useCallback(async () => {
    if (!currentBusiness.id) return;
    dispatch(
      //@ts-ignore
      getJiraTasks({ businessId: currentBusiness.id }),
    );
  }, [dispatch, currentBusiness]);

  useEffect(() => {
    if (!hasJiraSettingsPermission) return;

    fetchJiraSettings();
    fetchJiraTasks();
  }, [fetchJiraSettings, fetchJiraTasks, hasJiraSettingsPermission]);

  return (
    <ScanReportLayout emptyContentText="No issues available">
      {wcagReport.reportGroups[issueType].map(
        ([groupTitle, currentReportIssues]) =>
          currentReportIssues.length > 0 && (
            <ScanReportSection
              key={groupTitle}
              accordionState={accordionState}
              handleAccordionToggle={handleToggleAccordion}
              accordionId={groupTitle}
              reportSectionTitle={getIssueGroupName(groupTitle)}
              scanItems={currentReportIssues}
              devDomain={devDomain}
              wcagReport={wcagReport}
              issueType={issueType}
              activeItemId={activeItemId}
              toggleSectionItem={toggleSectionItem}
              scanReportItemDetails={ScanReportItemDetails}
              setSuccessMessage={setSuccessMessage}
              selectedIssuesId={selectedIssuesId}
              setSelectedIssuesId={setSelectedIssuesId}
              jiraSettings={jiraSettings}
            />
          ),
      )}
      {/* {wcagReport.list.all.length === 0 && !wcagReport.isDismissedReport && ( */}
      {/*  <CompletedStageWrapper> */}
      {/*    <CongratsText> */}
      {/*      CONGRATULATIONS YOU HAVE RESOLVED ALL ISSUES IN THIS CATEGORY */}
      {/*    </CongratsText> */}
      {/*    <ButtonWithIcon */}
      {/*      buttonText="Proceed to the next stage" */}
      {/*      iconSrc="/arrow-right.svg" */}
      {/*      iconAltText="right arrow icon" */}
      {/*      backgroundColor="#4AD493" */}
      {/*      borderStyle="none" */}
      {/*      onClickAction={proceedToNextStage} */}
      {/*      flexDirection="row-reverse" */}
      {/*    /> */}
      {/*  </CompletedStageWrapper> */}
      {/* )} */}
    </ScanReportLayout>
  );
};

export const Divider = styled(MuiDivider)`
  height: 2px;
`;

const CompletedStageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
`;

const CongratsText = styled.p`
  margin-bottom: 3%;
  color: #000a14;
  font-weight: 500;
`;
