import PropTypes from 'prop-types';
import React from 'react';

import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloseRounded } from '@mui/icons-material';
import styled from '@emotion/styled';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  paper: {
    width: '680px',
    maxHeight: '709px',
    overflowY: 'auto',
    backgroundColor: '#FFFFFF',
    maxWidth: '90%',
    paddingTop: '20px',
    borderRadius: '12px',
    '&:focus': {
      outline: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '95%',
      maxHeight: '93vh',
    },
  },
  header: {
    flex: '1',
    overflow: 'hidden',
  },
}));

export const ModalPanel = ({
  open,
  handleClose,
  children,
  title,
  subTitle,
  notification,
  modalWidth,
  modalBodyStyle,
}) => {
  const classes = useStyles();
  const [showNotificaiton, setShowNotificaiton] = React.useState(true);

  return (
    <Modal
      open={open}
      onClose={() => {
        setShowNotificaiton(true);
        handleClose();
      }}
      className={classes.modal}
      aria-labelledby="modal-panel-title"
      aria-describedby="modal-panel-description"
    >
      <PaperContainer containerWidth={modalWidth}>
        <div className="modal-panel" style={modalBodyStyle}>
          <div className="modal-header">
            <CloseButton
              onClick={handleClose}
              type="button"
              className="modal-close-button"
              aria-label="Close Modal"
              isBigModal={modalWidth !== undefined}
            >
              <CloseRounded />
            </CloseButton>
            <div className={classes.header}>
              <h2 id="modal-panel-title" className="modal-panel-title">
                {title}
              </h2>
            </div>
          </div>

          {notification && showNotificaiton && (
            <div className="modal-panel-notification">
              <div className="modal-panel-notification-icon">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  className="modal-panel-notification-icon-close"
                  src="close.png"
                  alt="close modal"
                  onClick={() => setShowNotificaiton(false)}
                />
              </div>
              <div className="modal-panel-notification-text">
                {notification}
              </div>
            </div>
          )}
          {subTitle && <div className="modal-panel-sub-title">{subTitle}</div>}
          {children}
        </div>
      </PaperContainer>
    </Modal>
  );
};

ModalPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  // eslint-disable-next-line react/require-default-props
  subTitle: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  // eslint-disable-next-line react/require-default-props
  notification: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  modalWidth: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  modalBodyStyle: PropTypes.object,
};

const CloseButton = styled.button`
  position: absolute;
  right: ${({ isBigModal }) => (isBigModal ? '40px' : '0')};
  top: ${({ isBigModal }) => (isBigModal ? '-10px' : '-8px')};

  @media screen and (max-width: 600px) {
    right: 2px;
    top: 2px;
  }
`;

const PaperContainer = styled.div`
  width: ${({ containerWidth }) => containerWidth || '680px'};
  max-height: 700px;
  overflow-y: auto;
  background-color: #ffffff;
  max-width: 90%;
  padding-top: 20px;
  border-radius: 12px;
  &:focus {
    outline: none;
  }

  @media screen and (max-width: 600px) {
    max-width: 95%;
    max-height: 96%;
  }
`;
