import styled from '@emotion/styled';
import {
  AccordionState,
  useAccessibilityAccordion,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Box, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IssueReport } from '../issue-report/issue-report';
import { ScanReportLayout } from '../scan-report/scan-report-layout';
import { ScanReportSection } from '../scan-report/scan-report-section';
import { useNotification } from '../../context/notifications-context';
import { EmptyAudit } from './empty-audit';

interface AuditReportViewProps {
  auditReport: ReportData;
  url: string;
  loadingAuditReport?: boolean;
  setLoadingAuditReport: React.Dispatch<React.SetStateAction<boolean>>;
}

const DEFAULT_AUDIT_GROUP_TITLES_ACCORDION_STATE: AccordionState = {
  clickables: true,
  forms: true,
  sensory: true,
  semantics: true,
  tables: true,
  objects: true,
  orientation: true,
  ruby: true,
  readability: true,
  graphics: true,
  images: true,
  applet: true,
  media: true,
  title: true,
  document: true,
  general: true,
};

export const AuditReportView = (props: AuditReportViewProps) => {
  const { auditReport, loadingAuditReport, setLoadingAuditReport } = props;
  const isMD = useMediaQuery((theme: any) => theme.breakpoints.up('md'));
  const [activeAuditGroup] = useState(1);
  const [isScanReportItemVisible, setIsScanReportItemVisible] = useState(false);
  const [activeItemId, setActiveItemId] = useState('');
  const toggleSectionItem = (id: string) => {
    if (isScanReportItemVisible && activeItemId === id) {
      setIsScanReportItemVisible(false);
    } else {
      setIsScanReportItemVisible(true);
      setActiveItemId(id);
    }
  };

  const { accordionState, handleAccordionToggle } = useAccessibilityAccordion(
    DEFAULT_AUDIT_GROUP_TITLES_ACCORDION_STATE,
  );
  const { setSuccessMessage } = useNotification();

  const getUpdatedTitles = (title: string): string => {
    switch (title) {
      case 'tables':
        return 'Table Data: Cells and Columns';
      case 'clickables':
        return 'Clickable Elements: Links and Buttons';
      case 'title':
        return 'Headings: Structured and Visual Headings.';
      case 'graphics':
        return 'Graphics: Icons, Images, and Backgrounds';
      default:
        return title;
    }
  };

  useEffect(() => {
    if (auditReport.reportGroups.all.length === 0 || !loadingAuditReport)
      return;
    setLoadingAuditReport(false);
  }, [auditReport]);

  return (
    <Container>
      <Box mt={isMD ? '32px' : '24px'} />
      {activeAuditGroup === 1 ? (
        <ScanReportLayout emptyContent={<EmptyAudit />}>
          {auditReport.reportGroups.all.map(
            ([groupTitle, currentReportIssues]) =>
              currentReportIssues.length > 0 && (
                <ScanReportSection
                  key={groupTitle}
                  accordionState={accordionState}
                  handleAccordionToggle={handleAccordionToggle}
                  accordionId={groupTitle}
                  reportSectionTitle={getUpdatedTitles(groupTitle)}
                  scanItems={currentReportIssues}
                  devDomain={undefined}
                  wcagReport={auditReport}
                  issueType={'all'}
                  activeItemId={activeItemId}
                  toggleSectionItem={toggleSectionItem}
                  setSuccessMessage={setSuccessMessage}
                  selectedIssuesId={{}}
                  setSelectedIssuesId={() => {}}
                  hideHowToFix
                />
              ),
          )}
        </ScanReportLayout>
      ) : (
        <IssueReport
          wcagReport={auditReport}
          list={auditReport.reportList}
          devDomain={undefined}
          setSuccessMessage={setSuccessMessage}
          selectedIssuesId={{}}
          setSelectedIssuesId={() => {}}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 85%;
`;
