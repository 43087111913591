/* eslint-disable camelcase */
import styled from '@emotion/styled';
import { useAnalytics } from '@equally-ai-front/common/src/contexts/AnalyticsProvider';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Extension from '../../../img/puzzle.png';
// import Settings from '../../../img/settings.png';
import SettingsIcon from '@mui/icons-material/Settings';
import CustomiseAppearance from '../../redesign/CustomiseAppearance';
import { ModalPanel } from '../../redesign/ModalPanel';
// import Code from '../../../img/code.png';
import CodeIcon from '@mui/icons-material/Code';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import {
  FREE_TRIAL_MESSAGE,
  PLAN_EXPIRY_MESSAGE,
  FLOWY_FREE_TRIAL_MESSAGE,
  FLOWY_PLAN_EXPIRY_MESSAGE,
} from '../../../common/magicValues';
import { formatExpiration, formatDate } from '../../../lib/date';
import { isPlansAvailable } from '../../../lib/plans';
import {
  resetDomainKey,
  setApiError,
  setApiSuccessMessage,
} from '../../../store/actions';
import { InstallExtensionContent } from '../../install-extension-step/components/install-extention-content';
import DomainInstallationCode from '../../redesign/DomainInstallationCode';
import SiteSettings from '../../redesign/SiteSettings';
import { ContainedButton, OutlinedButton } from '../ActionButton';
import {
  ErrorPillSmall,
  SuccessPillSmall,
  TypographySmall,
  WarningPillSmall,
} from '../Pill';
import { Box, Grid } from '@mui/material';
import { Notification, NotificationType } from './components/notification';
import DomainsAPI from '@equally-ai-front/common/src/api/domains';
import {
  CustomAccordionLayout,
  EmptyAccordionLayout,
  EmptyCustomAccordionLayoutProps,
} from './components/custom-accordion-layout';
import { StatementView, StatementViewProps } from './components/statement-view';
import {
  APPEARANCE_DEFAULT_SETTINGS,
  setDomainAppearanceSettings,
} from '../../../lib/domains';
import { withStyles } from '@mui/styles';
import { PrimaryButton } from '../../redesign/Controls';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import { usePlans } from '../../../providers/plans-context';

interface CustomizeAppearanceProps {
  domain: string;
  setCustomAppearanceOpened: (value: boolean) => void;
  appearance: any;
  handleUpdateAppearance: (appearance: any) => void;
}

interface SiteSettingsProps {
  closeSettings: () => void;
  domain: string;
  appearance: any;
  handleUpdateAppearance: (appearance: any) => void;
}

interface InstallExtensionContentProps {}

interface DomainInstallationCodeProps {
  domain: string;
  code: string;
}

interface InitCustomAccordionLayoutProps {
  makeComponent: <T>(props: T) => ReactNode;
  header: string;
  validateFallback?: any;
  image: ReactNode;
  validate: (props: any) => boolean;
}

const ACCORDION_COMPONENTS: Record<string, InitCustomAccordionLayoutProps> = {
  installation: {
    // @ts-ignore
    makeComponent: (props: DomainInstallationCodeProps) => (
      <DomainInstallationCode {...props} />
    ),
    image: (
      // @ts-ignore
      <CodeIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Code}
        role="presentation"
      />
    ),
    header: 'Installation',
  },
  'install-extension': {
    // @ts-ignore
    makeComponent: (props: InstallExtensionContentProps) => (
      <InstallExtensionContent {...props} />
    ),
    validate: ({ showInstallExtension }: { showInstallExtension: boolean }) =>
      showInstallExtension,
    image: (
      <img
        className="website-card-header-edit-btn-ico"
        alt="install extension"
        src={Extension}
        role="presentation"
      />
    ),
    header: 'Install Our Chrome Extension',
  },
  'custom-appearance': {
    // @ts-ignore
    makeComponent: (props: CustomizeAppearanceProps) => (
      <CustomiseAppearance {...props} />
    ),
    image: (
      // @ts-ignore
      <PaletteOutlinedIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Paint}
        role="presentation"
      />
    ),
    header: 'Customize',
  },
  settings: {
    // @ts-ignore
    makeComponent: (props: SiteSettingsProps) => <SiteSettings {...props} />,
    image: (
      // @ts-ignore
      <SettingsIcon
        className="website-card-header-edit-btn-ico"
        alt=""
        // src={Settings}
      />
    ),
    header: 'Settings',
  },
  statement: {
    // @ts-ignore
    makeComponent: (
      props: StatementViewProps & EmptyCustomAccordionLayoutProps,
    ) => {
      const { planId } = props;
      return !planId || planId === 0 ? (
        <EmptyAccordionLayout {...props} />
      ) : (
        <StatementView {...props} />
      );
    },
    validate: ({ planId }: { planId: number | undefined }) =>
      planId !== undefined && planId !== 0,
    validateFallback: (
      props: StatementViewProps & EmptyCustomAccordionLayoutProps,
    ) => {
      return (
        <EmptyAccordionLayout
          {...props}
          ctaButton={
            <UpgradeButton onClick={() => props.onChoosePlan(props.type)}>
              Upgrade to view
            </UpgradeButton>
          }
        />
      );
    },
    header: 'Accessibility Statement',
    image: (
      // @ts-ignore
      <CardMembershipIcon
        style={{ marginTop: '3px' }}
        className="website-card-header-edit-btn-ico"
        alt=""
        role="presentation"
      />
    ),
  },
};

interface ManageWidgetCardProps {
  domain: any;
  open: boolean;
  handleClose: () => void;
  showActivateModal: () => void;
  addDomainToActivateLicence: (domain: any) => void;
  showInstallExtension: boolean;
  isNewDomain: boolean;
  onChoosePlan: (productType: string) => void;
}
const NotificationComponent = ({
  isExpired,
  isWithoutPlan,
  domain,
}: {
  isExpired: boolean;
  isWithoutPlan: boolean;
  domain: any;
}) => {
  // Determine the notification message and type based on conditions
  let notificationMessage = '';
  let notificationType: NotificationType | null = null;

  if (isExpired) {
    notificationMessage =
      domain.type === 'flowy' ? FLOWY_PLAN_EXPIRY_MESSAGE : PLAN_EXPIRY_MESSAGE;
    notificationType = NotificationType.Error;
  } else if (!isExpired && isWithoutPlan) {
    notificationMessage =
      domain.type === 'flowy' ? FLOWY_FREE_TRIAL_MESSAGE : FREE_TRIAL_MESSAGE;
    notificationType = NotificationType.Warning;
  }

  // Render the component
  return (
    <>
      {notificationMessage && notificationType && (
        <Notification message={notificationMessage} type={notificationType} />
      )}
    </>
  );
};
const ManageWidgetCard = ({
  domain,
  open,
  handleClose,
  showActivateModal,
  addDomainToActivateLicence,
  showInstallExtension,
  isNewDomain,
  onChoosePlan,
}: ManageWidgetCardProps) => {
  const [currentActiveOption, setCurrentActiveOption] = useState<string | null>(
    null,
  );
  const [appearance, setAppearance] = useState({
    ...APPEARANCE_DEFAULT_SETTINGS,
  });
  const [widgetSampleCode, setWidgetSampleCode] = useState('');
  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const { activePlans: plansData } = usePlans();

  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const onChange = useCallback(
    (option: string, trigger = 'click') => {
      if (currentActiveOption === option) {
        setCurrentActiveOption(null);
        return;
      }

      setCurrentActiveOption(option);
      analytics.track('Website Settings Option Clicked', {
        option: option,
        url: domain.name,
        id: domain.id,
        trigger: trigger,
      });
    },
    [analytics, currentActiveOption, domain.id, domain.name],
  );
  const handleUpdateAppearance = useCallback(
    (
      businessId: number,
      domainName: string,
      newAppearance: any,
      isSettings: boolean,
    ) => {
      const update = async () => {
        const { error } = await DomainsAPI.updateDomainAppearanceSettings(
          businessId,
          domainName,
          newAppearance,
        );

        if (error) {
          const message = isSettings
            ? 'Could not save settings'
            : 'Could not save appearance settings';
          dispatch(setApiError(`${message} for ${domainName}`));
          return;
        }
        const message = isSettings
          ? 'Settings saved'
          : 'Appearance Settings saved';
        dispatch(setApiSuccessMessage(`${message} for ${domainName}`));
        setAppearance(newAppearance);
      };

      void update();
    },
    [dispatch],
  );
  const getComponentProps = useCallback(
    (key: string) => {
      switch (key) {
        case 'installation':
          return {
            domain: domain.name,
            code: widgetSampleCode,
          };
        case 'custom-appearance':
          return {
            domain: domain.name,
            handleUpdateAppearance,
            setCustomAppearanceOpened: () => onChange('custom-appearance'),
            appearance,
          };
        case 'settings':
          return {
            domain: domain.name,
            closeSettings: () => onChange('settings'),
            appearance,
            handleUpdateAppearance,
          };
        case 'install-extension':
          return { showInstallExtension };
        case 'statement':
          return {
            planId: domain.plan_id,
            name: domain.name,
            defaultLanguage:
              appearance.widgetConfigSiteAdditional.defaultLanguage,
            statementLink:
              appearance.widgetConfigSiteAdditional.accessibilityStatementLink,
            onChoosePlan: onChoosePlan,
            type: domain.type,
          };
        default:
          return {};
      }
    },
    [
      domain.name,
      domain.plan_id,
      widgetSampleCode,
      handleUpdateAppearance,
      appearance,
      showInstallExtension,
      onChange,
      onChoosePlan,
      domain.type,
    ],
  );
  const views: ReactNode[] = [];
  Object.keys(ACCORDION_COMPONENTS).forEach((key) => {
    const componentProps = getComponentProps(key);
    const { makeComponent, validateFallback, header, image, validate } =
      ACCORDION_COMPONENTS[key];
    let Component;
    if (validate && !validate(componentProps)) {
      if (!validateFallback) {
        return;
      }
      Component = validateFallback({ ...componentProps, image, header });
      views.push(Component);
      return;
    }

    Component = makeComponent(componentProps);
    views.push(
      <CustomAccordionLayout
        type={key}
        isExpanded={currentActiveOption === key}
        onChange={() => onChange(key)}
        header={header}
        image={image}
        Component={Component}
      />,
    );
  });

  const handleCloseModal = () => {
    dispatch(resetDomainKey());
    handleClose();
    setWidgetSampleCode('');
  };

  const onActivateLicense = () => {
    addDomainToActivateLicence(domain);
    showActivateModal();
    handleCloseModal();
  };
  const getWidgetSampleCode = async (
    domainName: string,
    businessId: number,
  ) => {
    const { data, error } = await DomainsAPI.getSampleCode(
      domainName,
      businessId,
    );

    if (error) {
      return;
    }

    setWidgetSampleCode(data);
  };

  const getAppearanceSettings = useCallback(
    (domainName: string, businessId: number) => {
      const get = async () => {
        const { data, error } = await DomainsAPI.getDomainAppearanceSettings(
          domainName,
          businessId,
        );

        if (error) {
          return;
        }

        setAppearance(setDomainAppearanceSettings(data));
      };

      void get();
    },
    [],
  );

  useEffect(() => {
    const getInitialData = async () => {
      await Promise.all([
        getWidgetSampleCode(domain.name, currentBusiness.id),
        getAppearanceSettings(domain.name, currentBusiness.id),
      ]);
    };
    void getInitialData();
  }, [dispatch, domain, currentBusiness, getAppearanceSettings]);

  useEffect(() => {
    if (isNewDomain) {
      onChange('installation', 'new-domain');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewDomain]);

  const { plan_template } =
    plansData.find((plan: any) => plan?.id === domain.plan_id) || {};

  const isExpired = formatExpiration(domain.expiration) === 'Expired';
  const isWithoutPlan = domain.plan_id === 0;

  const formattedDate = formatDate(domain.expiration);

  return (
    <>
      <ModalPanel
        open={open}
        handleClose={handleCloseModal}
        title={domain.name}
      >
        <ButtonContainer>
          {(isExpired || isWithoutPlan) && (
            <ContainedButton onClick={() => onChoosePlan(domain.type)}>
              Choose a plan
            </ContainedButton>
          )}
          {isPlansAvailable(plansData) && isWithoutPlan && (
            <OutlinedButton
              disabled={domain.plan_id}
              onClick={onActivateLicense}
            >
              Activate License
            </OutlinedButton>
          )}
        </ButtonContainer>
        <NotificationComponent
          domain={domain}
          isExpired={isExpired}
          isWithoutPlan={isWithoutPlan}
        />
        <Grid container alignItems="center" justifyContent="center">
          <GridItem item md={4} xs={4}>
            <Subtitle>Status</Subtitle>
            {domain.active ? (
              <SuccessPill>Active</SuccessPill>
            ) : (
              <ErrorPill>Inactive</ErrorPill>
            )}
          </GridItem>
          <GridItem item md={4} xs={4}>
            <Subtitle>Plan</Subtitle>
            {plan_template?.name ? (
              <SuccessPill>{plan_template.name}</SuccessPill>
            ) : (
              <WarningPill>Trial</WarningPill>
            )}
          </GridItem>
          <GridItem item md={4} xs={4}>
            <Subtitle>Expiration</Subtitle>

            {!isExpired ? (
              <SuccessPill>{formattedDate}</SuccessPill>
            ) : (
              <WarningPill>{formattedDate}</WarningPill>
            )}
          </GridItem>
        </Grid>
        <Box mb="18px" />
        <div className="website-card-controls">
          <div>{views}</div>
        </div>
      </ModalPanel>
    </>
  );
};

export default ManageWidgetCard;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;

  &:not(:empty) {
    margin-bottom: 20px;
  }

  & > button,
  & > a {
    text-transform: initial;
    padding: 6px 16px 6px 12px;
    height: 34px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    border-radius: 8px;
  }
`;

const Subtitle = styled(TypographySmall)`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 3px;
`;

const GridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SuccessPill = styled(SuccessPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;

const WarningPill = styled(WarningPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;

const ErrorPill = styled(ErrorPillSmall)`
  min-width: 60px;
  text-align: center;

  @media screen and (max-width: 600px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
  }
`;
export const UpgradeButton = withStyles((theme) => {
  return {
    root: {
      height: '33px',
      fontSize: '13px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.on.lightHigh,
      border: '1px solid transparent',
      '&:hover': {
        color: theme.palette.on.darkHigh,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  };
})(PrimaryButton);
