import {
  Box,
  CircularProgress,
  FormHelperText,
  IconButton,
  Input,
} from '@mui/material';
import React, { useState, useCallback } from 'react';
import AuditAPI from '@equally-ai-front/common/src/api/audit';
import { useFormik } from 'formik';
import { GenericInput } from '../../../components/accessibility-components/generic-input';
import styled from 'styled-components';
import { PrimaryButton } from '../../../components/redesign/Controls';
import {
  TypographyH1,
  TypographyH2,
  TypographyH3,
} from '../../../components/common/styled';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { CreateScanUrlsList } from './create-scan-urls-list';
import { NewScanInfo } from '@equally-ai-front/common/src/types/issues';
import { getUrlWithoutHttpPrefixAndWWW } from '@equally-ai-front/common';
import { useDispatch } from 'react-redux';
import { setApiError } from '../../../store/actions';
import { Close } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { AttributesChip } from '@equally-ai-front/common/src/components/attribute-chip/attributes-chip';
import { getPathname } from '@equally-ai-front/common/src/utils';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/use-app-dispach-selector';

interface SetupScanForm {
  scanName: string;
  wcagVersion: string;
  pageUrls: string;
}

interface SetupScanProps {
  handleClose: () => void;
  devDomain: DevDomain;
  handleAudit: (
    url: string,
    cb?: () => void,
    scanDetails?: NewScanInfo,
  ) => void;
}

const validateFields = (values: SetupScanForm): string | null => {
  if (!values.scanName) {
    return 'Scan name is required.';
  }
  if (values.pageUrls.length === 0) {
    return 'Enter at least one Page url.';
  }
  return null;
};

export const SetupScan = ({
  devDomain,
  handleClose,
  handleAudit,
}: SetupScanProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentBusiness } = useAppSelector((state) => state.business);
  const [isExtractMode, setIsExtractMode] = useState(false);
  const [showPageList, setShowPageList] = useState(false);
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [domainPages, setDomainPages] = useState<string[]>([]);
  const [newUrls, setNewUrls] = useState<string>('');

  const onFormSubmit = (values: SetupScanForm) => {
    if (!currentBusiness) return;
    const errorMessage = validateFields(values);
    if (errorMessage) {
      dispatch(setApiError(errorMessage));
      return;
    }
    const totalUrls = values.pageUrls.split(/,|\n/).filter((url) => url.trim());
    handleAudit(totalUrls.join(';'), undefined, {
      scan_name: values.scanName,
      target: values.wcagVersion,
    });
    navigate(`/${currentBusiness.slug}/developers/${devDomain.id}/flowy/scans`);
    handleClose();
  };

  const {
    handleChange: handleFormChange,
    values,
    handleSubmit,
    setFieldValue,
  } = useFormik<SetupScanForm>({
    initialValues: {
      scanName: '',
      wcagVersion: '',
      pageUrls: '',
    },
    onSubmit: onFormSubmit,
  });

  const handleDomainTyping = (e: any) => {
    const { value } = e.target;

    // Check if user hit Enter or the input includes commas
    if (value.includes(',')) {
      const currentUrls = value
        .split(/,|\n/)
        .map((url: string) => getPathname(url.trim()))
        .join(',');
      setFieldValue('pageUrls', [values.pageUrls, currentUrls].join(','));
      setNewUrls('');
    } else {
      setNewUrls(value);
    }
  };
  const handleKeyPress = (e: any) => {
    const { value } = e.target;

    if (e.key === 'Enter' || value.includes(',')) {
      e.stopPropagation();
      const currentUrls = value
        .split(/,|\n/)
        .map((url: string) => getPathname(url.trim()))
        .join(',');
      setFieldValue('pageUrls', [values.pageUrls, currentUrls].join(','));
      setNewUrls('');
    }
  };

  const handleFetchDomainPages = useCallback(async () => {
    if (!sitemapUrl) return;
    if (!sitemapUrl.includes(devDomain.name)) {
      dispatch(
        setApiError(
          `You cannot fetch pages for ${sitemapUrl} while on ${devDomain.name}.`,
        ),
      );
      return;
    }
    setIsLoading(true);
    const { data, isSuccess } = await AuditAPI.getDomainPages(
      devDomain.id,
      devDomain.business_id,
      getUrlWithoutHttpPrefixAndWWW(sitemapUrl),
    );

    setIsLoading(false);
    if (!isSuccess) {
      dispatch(setApiError('Error fetching your domain pages'));
      return;
    }

    if (!data) {
      dispatch(setApiError("Couldn't find your domain pages"));
      setIsExtractMode(false);
      return;
    }

    setDomainPages(data);
    setIsExtractMode(false);
    setShowPageList(true);
  }, [dispatch, sitemapUrl, devDomain]);

  const onSwitchToExtractMode = () => setIsExtractMode(true);
  const onSwitchToShowPageList = () => setShowPageList(true);
  const addSelectedUrls = (urls: string) => {
    setFieldValue('pageUrls', urls);
    setIsExtractMode(false);
    setShowPageList(false);
  };

  const onDeleteUrl = (url: string) => {
    const urlsArray = values.pageUrls.split(/,|\n\s*\n/);

    const split = urlsArray.filter((pageUrl) => pageUrl !== url);
    setFieldValue('pageUrls', split.join(','));
  };

  const totalUrls = values.pageUrls.split(/,|\n/).filter((url: string) => url);

  return (
    <Container onSubmit={handleSubmit}>
      {!showPageList ? (
        <>
          <StyledHeader>
            <MainHeader>Setup a website scan</MainHeader>
          </StyledHeader>
          <InputBox>
            <GenericInput
              onChange={handleFormChange}
              value={values.scanName}
              ariaProps={{ 'aria-label': 'Scan name' }}
              id="scan-name"
              label="Scan name"
              type="text"
              name="scanName"
              required
              fullWidth
            />
          </InputBox>
          <Box mt="20px" />
          <StyledHeader>
            <SubHeader>Add pages:</SubHeader>
            <Box margin="2px 0 3px 0" />
            <InfoHeader>
              *Enter pages separated by commas (,) or a new line
            </InfoHeader>
          </StyledHeader>
          <Box
            width="100%"
            style={{
              position: 'relative',
              border: '1px solid #c4c4c4',
              borderRadius: '4px',
              minHeight: '24vh',
            }}
          >
            <ChipsContainer>
              <Input
                onChange={(
                  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => {
                  handleDomainTyping(e);
                }}
                onKeyPress={handleKeyPress}
                placeholder="/"
                value={newUrls}
                name="newUrls"
                // ariaProps={{ 'aria-label': 'Add Pages' }}
                id="add-pages"
                // label="Add Pages"
                // variant={undefined}
                disableUnderline
                type="text"
                style={{ width: '100%' }}
                // minRows={isExtractMode ? 12 : 15}
              />
              {totalUrls.map((url) => (
                <AttributesChip
                  value=""
                  attribute={url}
                  key={url}
                  onDelete={url ? () => onDeleteUrl(url) : undefined}
                />
              ))}
            </ChipsContainer>
          </Box>
          {totalUrls.length > 0 && (
            <FormHelperText style={{ alignSelf: 'flex-start' }}>
              {totalUrls.length} URLs added
            </FormHelperText>
          )}
          <Box mt={isExtractMode ? '20px' : '40px'} />
          {isExtractMode && domainPages.length === 0 && (
            <Box display="flex" alignItems="center" width="100%" mb="20px">
              {isLoading ? (
                <>
                  <Spinner />
                  <AlertBox variant="outlined" severity="info" color="info">
                    {sitemapUrl}
                  </AlertBox>
                </>
              ) : (
                <>
                  <GenericInput
                    onChange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                    ) =>
                      setSitemapUrl(
                        getUrlWithoutHttpPrefixAndWWW(e.target.value),
                      )
                    }
                    value={sitemapUrl}
                    ariaProps={{
                      'aria-label': 'Enter domain URL or XML sitemap URL',
                    }}
                    id="add-pages"
                    label="Enter domain URL or XML sitemap URL"
                    type="text"
                    name="pageUrls"
                    placeholder={`${devDomain.name}/sitemap.xml`}
                    required
                    style={{ width: '100%' }}
                    disabled={isLoading}
                  />
                  <CloseButton onClick={() => setIsExtractMode(false)}>
                    <Close />
                  </CloseButton>
                </>
              )}
            </Box>
          )}
          <ButtonContainer>
            {!isExtractMode && domainPages.length === 0 && (
              <TextButton
                variant="text"
                color="primary"
                onClick={onSwitchToExtractMode}
              >
                Extract URLs from sitemap
              </TextButton>
            )}
            {domainPages.length > 0 && (
              <TextButton
                variant="text"
                color="primary"
                onClick={onSwitchToShowPageList}
              >
                View Pages
              </TextButton>
            )}

            <Box display="flex" ml="auto">
              <PrimaryButton
                color="primary"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </PrimaryButton>
              <Box mr="5px" />

              {isExtractMode ? (
                <PrimaryButton
                  color="primary"
                  variant="contained"
                  onClick={handleFetchDomainPages}
                  disabled={isLoading}
                >
                  Fetch
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => onFormSubmit(values)}
                  color="primary"
                  variant="contained"
                >
                  Create
                </PrimaryButton>
              )}
            </Box>
          </ButtonContainer>
        </>
      ) : (
        <CreateScanUrlsList
          domain={devDomain}
          handleClose={() => setShowPageList(false)}
          currentUrls={values.pageUrls}
          pageUrls={domainPages}
          handleAddUrls={addSelectedUrls}
        />
      )}
    </Container>
  );
};

const StyledHeader = styled.div`
  align-self: flex-start;
  margin-bottom: 20px;

  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

const MainHeader = styled(TypographyH1)`
  font-size: 24px;
  font-weight: 700;
`;
const SubHeader = styled(TypographyH2)`
  font-size: 16px;
  font-weight: 700;
`;

const InfoHeader = styled(TypographyH3)`
  font-size: 12px;
  font-weight: 500;
`;

const Container = styled.form`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  max-width: 100%;
  min-height: 600px;

  @media screen and (max-width: 600px) {
    padding: 5px 10px;
    justify-content: flex-start;
    min-width: auto;
    width: 100%;
    min-height: 90%;
    max-height: 90%;
  }
`;

const InputBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;

    & > div {
      width: 100%;
      margin: 4px 0;
    }
  }
`;

const ButtonContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & button {
    height: 38px;
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 45px;
  }
`;

const TextButton = styled(PrimaryButton)`
  @media screen and (max-width: 600px) {
    font-size: 12px !important;
  }
`;

const Spinner = styled(CircularProgress)`
  width: 16px !important;
  height: 16px !important;
  margin-left: 3px;
`;

const CloseButton = styled(IconButton)``;

const AlertBox = styled(Alert)`
  color: #454284;
  border: 1px solid rgba(69, 66, 132, 0.5);
  width: 100%;
  margin-left: 5px;

  & svg {
    color: #454284;
  }
`;

const ChipsContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  border-radius: inherit;
  //position: absolute;
  top: 11px;
  left: 3px;
  max-width: 98%;
  padding: 6px;
  background-color: #fff;
`;
