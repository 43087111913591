import * as Yup from 'yup';

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

export const EmailValidator = Yup.string()
  .matches(EMAIL_REGEX, 'Invalid email')
  .required('Email is required')
  .trim();

export const PhoneNumberValidator = Yup.string()
  .trim()
  .max(2048, 'Length should not exceed 2048 symbols')
  .matches(
    /^[+]?[-\s/0-9]*[(]?[0-9]+[)]?[-\s/0-9]*$/i,
    'Phone Number must be valid and contain only chars listed below: + - ( ) 0-9 and spaces',
  )
  .nullable();

export const NameValidator = Yup.string()
  .trim()
  .max(2048, 'Length should not exceed 2048 symbols')
  .required('Full Name is required');

export const PasswordLengthValidator = Yup.string().min(
  8,
  'Password is too short - should be 8 chars minimum.',
);

export const PasswordOneLowercaseCharValidator = Yup.string().matches(
  /(?=.*[a-z])/,
  'Password must contain at least 1 lowercase alphabetical character',
);

export const PasswordOneUppercaseCharValidator = Yup.string().matches(
  /(?=.*[A-Z])/,
  'Password must contain at least 1 uppercase alphabetical character',
);

export const PasswordOneNumberValidator = Yup.string().matches(
  /(?=.*[\d])/,
  'Password must contain at least 1 numeric character',
);

export const PasswordOneSpecialCharValidator = Yup.string().matches(
  // Must match all special character [$&+,:;=?@#_`~_\[\]\{\}\/|'<>.^*()%!-]
  /(?=.*[^a-zA-Z\d])/,
  'Password must contain at least one special character,',
);

export const PasswordValidator = Yup.string()
  .concat(PasswordLengthValidator)
  .concat(PasswordOneLowercaseCharValidator)
  .concat(PasswordOneUppercaseCharValidator)
  .concat(PasswordOneNumberValidator)
  .concat(PasswordOneSpecialCharValidator);

export const ConfirmPasswordValidator = Yup.string()
  .required('Password (Confirm) is required')
  .oneOf([Yup.ref('password'), null], 'Passwords must match');

export const loginFormValidationSchema = Yup.object().shape({
  email: EmailValidator,
  password: Yup.string().required('Password is required'),
});

export const resetPassowordFormValidationSchema = Yup.object().shape({
  newPassword: PasswordValidator,
  newPasswordVerification: PasswordValidator.oneOf(
    [Yup.ref('newPassword'), null],
    'Passwords must match',
  ),
});

export const signupFormValidationSchema = Yup.object().shape({
  email: EmailValidator,
  phoneNumber: PhoneNumberValidator,
  name: NameValidator,
  password: PasswordValidator.required('Password is required'),
  checked: Yup.boolean()
    .required('Must Accept Terms and Conditions')
    .oneOf([true], 'Must Accept Terms and Conditions'),
});
