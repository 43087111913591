import { Box, Checkbox, Paper } from '@mui/material';
import styled from 'styled-components';
import { ContainedButton } from '../../../components/common/ActionButton';
import { TypographyH1 } from '../../../components/common/styled';
import TextLink from '../../../components/common/TextLink';

export const PageContent = styled.div`
  max-width: 390px;
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
    max-width: 100%;
  }

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled(TypographyH1)`
  && {
    font-size: 2.8em;
    font-weight: 400;
    text-align: center;
  }

  @media screen and (max-width: 600px) {
    font-size: 28px;
    margin-top: 10px;
    text-align: center;
  }
`;

export const StyledLink = styled(TextLink)`
  transition: border 0.3s ease-out;
  border-bottom: 2px solid #454284;
  font-weight: 500;
  line-height: initial;
  color: inherit;
  width: fit-content;

  display: inline-block;

  &:hover {
    border-bottom-color: #c2f4d7;
  }
  line-height: 150%;
  color: rgba(0, 10, 20, 0.85);
`;

export const ORText = styled.div`
  color: rgba(0, 10, 20, 0.65);
  margin: 12px;
  text-align: center;

  font-size: 12px;
  line-height: 150%;

  @media screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 150%;
  }
`;

export const BottomSection = styled.div`
  color: rgba(0, 10, 20, 0.65);

  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 150%;
  }
`;

export const ActionButton = styled(ContainedButton)`
  text-transform: initial;
`;

export const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  //width: 50%;
  height: 100%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const TermsCheckbox = styled(Checkbox)`
  &:focus-within > span {
    outline: 3px solid var(--equally-focus-color, #007bff) !important;
    outline-offset: 5px !important;
    outline-style: inset !important;
  }
`;

export const OutlineGreenCard = styled(Paper)`
  background: #ecfcf2;
  color: #093c2b;

  border: 2px solid rgba(9, 60, 43, 0.32);
  box-sizing: border-box;
  border-radius: 12px;
`;
