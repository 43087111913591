import React from 'react';
import styled from 'styled-components';
import ScanIcon from '../../assets/scan-icon-bg-white.svg';
import { AccessilitySummary } from '../accessibility-summary/accessibility-summary';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';

interface AuditViewHeaderProps {
  report: ReportData;
  loading: boolean;
  scanImageUrl: string;
  isWidgetAppeared: boolean;
}

export const AuditViewHeader = ({
  report,
  loading,
  scanImageUrl,
  isWidgetAppeared,
}: AuditViewHeaderProps) => {
  return (
    <Wrapper>
      <AuditViewHeaderContainer loading={loading.toString()}>
        {loading && (
          <ScanIconWrapper className="skeleton skeleton-text">
            <ScanImgIcon src={ScanIcon} />
          </ScanIconWrapper>
        )}

        <Container>
          <HeaderInfoWrapper>
            <AccessilitySummary
              isWidgetAppeared={isWidgetAppeared}
              report={report}
              loading={loading}
              scanImageUrl={scanImageUrl}
            />
          </HeaderInfoWrapper>

          {loading && (
            <ScanningTextWrapper>
              <Title>Scanning your website</Title>
              <Text>Preparing your report</Text>
            </ScanningTextWrapper>
          )}
        </Container>
      </AuditViewHeaderContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const AuditViewHeaderContainer = styled.div<{ loading: string }>`
  ${(p) =>
    p.loading === 'true'
      ? `
        max-width: 90%;
        display: flex;
        justify-content: space-between;

        @media (pointer: none), (pointer: coarse) {
            max-width: 90%;
            flex-direction: column-reverse;
        }
    `
      : ``}
`;

const ScanIconWrapper = styled.div`
  flex-basis: 40%;
`;

const ScanImgIcon = styled.img`
  width: 100%;
`;

const Container = styled.div`
  flex-basis: 45%;
`;

const HeaderInfoWrapper = styled.div``;

const ScanningTextWrapper = styled.div`
  margin-top: 20px;

  @media screen and (max-width: 1030px) {
    margin-top: 5px;
  }

  @media (pointer: none), (pointer: coarse) {
    text-align: center;
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 0;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  margin-top: 0;
`;
