import styled from '@emotion/styled';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { HashLink as RouterLink } from 'react-router-hash-link';

const TextLink = (props) => {
  const { to } = props;
  const linkProps = {
    ...props,
    ...(to ? { component: RouterLink } : {}),
  };

  return <StyledLink {...linkProps} color="secondary" />;
};

TextLink.propTypes = {
  to: PropTypes.any,
  target: PropTypes.string,
  rel: PropTypes.string,
  onClick: PropTypes.func,
};

TextLink.defaultProps = {
  to: null,
  target: '',
  rel: '',
  onClick: () => {},
};

export default TextLink;

/** Styled Components */

const StyledLink = styled(Link)`
  transition: border 0.3s ease-out;
  border-bottom: 2px solid transparent;
  font-weight: 500;
  line-height: initial;
  color: inherit;

  display: inline-block;

  &:hover {
    text-decoration: none;
    border-bottom: 2px solid #6966b2;
  }
`;
