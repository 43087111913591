import { selectDismissedIssues } from '@equally-ai-front/common/src/redux/developer-slice/selector';
import {
  Issue,
  IssueSeverity,
  IssueStatusEnum,
  IssueStatuses,
} from '@equally-ai-front/common/src/types/issues';
import { getIssuesCount } from '@equally-ai-front/common/src/utils';
import {
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {
  setIssueSeverityFilter,
  setIssueStatuses,
  setReportType,
  setNativeHTMLElementsFilter,
} from '../../../store/dashboard-slice/dashboard';
import {
  Checkbox,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import Select, { SelectChangeEvent, selectClasses } from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  DashboardFilterStatus,
  DashboardReportType,
} from '../../../store/dashboard-slice/types';
import { FilterOption, FilterSelect } from './filter-select';
import { setApiError } from '../../../store/actions';
import { CreateJiraTaskPopup } from '../../../components/create-jira-task/popup';
import { JiraSelectDropdown } from '../../../components/create-jira-task/jira-select-dropdown';
import { nativeHTMLElementsSelectOptions } from '../const';
import { RootState } from '../../../store';

enum DROPDOWN_OPTIONS {
  CreateJiraIssues = 'Create Jira Issues',
}

const severityOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'basic',
    label: 'Critical',
  },
  {
    value: 'advanced',
    label: 'Potential',
  },
  {
    value: 'potential',
    label: 'Best practice',
  },
];

const groupByOptions: FilterOption[] = [
  {
    value: 'html',
    label: 'HTML Elements',
  },
  {
    value: 'wcag',
    label: 'WCAG',
  },
  {
    value: 'recommendation',
    label: 'Recommendations',
  },
];
const statusesOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'dismissed',
    label: 'Dismissed',
  },
];
const issuesGroupType = ['wcag', 'html', 'recommendation'];

interface FiltersProps {
  activeFilter: DashboardFilterStatus;
  severity: IssueSeverity | 'all';
  reportType: DashboardReportType;
  disableFilterSelect: boolean;
  currentIssueStatus: IssueStatuses;
  selectedIssuesId: Record<number, boolean>;
  hasJiraSettingsPermission: boolean;
  visibleWcagReport: ReportData;
  issues: Issue[];
}

interface NativeElementSelectOption {
  label: string;
  key: string;
}

export const Filters = (props: FiltersProps) => {
  const {
    currentIssueStatus,
    severity,
    reportType,
    disableFilterSelect,
    selectedIssuesId,
    hasJiraSettingsPermission,
    visibleWcagReport,
    issues,
  } = props;

  const dispatch = useDispatch();
  const [showCreateJiraIssuesPopup, setShowCreateJiraIssuesPopup] =
    useState(false);
  const handleToggleCreateJiraIssuesPopup = () => {
    setShowCreateJiraIssuesPopup(!showCreateJiraIssuesPopup);
  };
  const [selectedOption, setSelectedOption] = useState<string>(
    DROPDOWN_OPTIONS.CreateJiraIssues,
  );
  const nativeHTMLElementsFilter = useSelector(
    (state: RootState) => state.dashboard.nativeHTMLElementsFilter,
  );
  const [selectOptions] = useState<string[]>(nativeHTMLElementsFilter);

  const OPTIONS = [
    {
      label: DROPDOWN_OPTIONS.CreateJiraIssues,
      icon: (
        <CancelPresentationIcon
          sx={{ height: '18px', width: '18px', marginRight: '5px' }}
        />
      ),
      handleClickAction: () => {
        if (Object.entries(selectedIssuesId).length === 0) {
          dispatch(setApiError('Select an issue before proceeding!'));
          return;
        }
        handleToggleCreateJiraIssuesPopup();
      },
    },
  ];

  const selectActiveFilter = (filterType: DashboardFilterStatus): void => {
    dispatch(setIssueStatuses(filterType));
  };
  const handleSeverityChange = (filterType: IssueType): void => {
    dispatch(setIssueSeverityFilter(filterType));
  };
  const handleReportTypeChange = (newReportType: DashboardReportType): void => {
    dispatch(setIssueStatuses('all'));
    dispatch(setIssueSeverityFilter('all'));
    dispatch(setReportType(newReportType));
  };
  const handleChange = (
    evt: SelectChangeEvent<typeof nativeHTMLElementsFilter>,
  ) => {
    const {
      target: { value },
    } = evt;
    dispatch(
      setNativeHTMLElementsFilter(
        typeof value === 'string' ? value.split(',') : value,
      ),
    );
  };

  return (
    <Wrapper>
      <FilterSelectDiv>
        <FilterSelect
          id="groupBy"
          options={groupByOptions}
          value={reportType}
          setValue={handleReportTypeChange}
          formInputLabel="Group By"
          marginRight="15px"
          disabled={disableFilterSelect}
        />
        {issuesGroupType.includes(reportType) && (
          <FilterSelect
            id="issue-statuses"
            options={statusesOptions}
            value={currentIssueStatus}
            setValue={selectActiveFilter}
            formInputLabel="Status"
            marginRight="15px"
            disabled={false}
          />
        )}
        {issuesGroupType.includes(reportType) && (
          <FilterSelect
            id="severity"
            options={severityOptions}
            value={severity}
            setValue={handleSeverityChange}
            formInputLabel="Severity"
            marginRight="15px"
            disabled={disableFilterSelect}
          />
        )}
        <CustomFormControl
          sx={{
            m: 1,
            minWidth: '120px',
            width: '186px',
            margin: '0px 15px 15px 0px',
          }}
        >
          <InputLabel
            id="nativeHtmlMultiSelect"
            sx={{
              transform: 'translate(14px, 9px) scale(1)',
              '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -7px) scale(0.75)',
              },
            }}
            className="input-label"
          >
            Native HTML Elements
          </InputLabel>
          <Select
            labelId="nativeHtmlMultiSelect"
            id="nativeHtmlMultiSelectCheckbox"
            className="select-label"
            multiple
            value={nativeHTMLElementsFilter}
            onChange={handleChange}
            sx={{
              '.MuiSelect-select': {
                padding: '8.5px 30px 8.5px 14px',
              },
            }}
            input={
              <OutlinedInput
                label="Native HTML Elements"
                sx={{
                  height: '36px',
                  lineHeight: '36px',
                  padding: '0 12px',
                }}
              />
            }
            renderValue={(selected) =>
              `${selected.length > 1 ? `Components (${selected.length})` : selected.join('')}`
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
          >
            {selectOptions.map((nativeHtmlElement) => (
              <MenuItem key={nativeHtmlElement} value={nativeHtmlElement}>
                <Checkbox
                  checked={nativeHTMLElementsFilter.includes(nativeHtmlElement)}
                />
                <ListItemText primary={nativeHtmlElement} />
              </MenuItem>
            ))}
          </Select>
        </CustomFormControl>
      </FilterSelectDiv>
      {hasJiraSettingsPermission && (
        <JiraSelectDropdownWrapper>
          <JiraSelectDropdown
            isDisabled={visibleWcagReport.list.all.length === 0}
            options={OPTIONS}
            handleSelectOption={(opt) => setSelectedOption(opt)}
            selectedOption={selectedOption}
          />
        </JiraSelectDropdownWrapper>
      )}
      {showCreateJiraIssuesPopup && (
        <CreateJiraTaskPopup
          isOpen={showCreateJiraIssuesPopup}
          handleClose={handleToggleCreateJiraIssuesPopup}
          issues={issues}
          issuesDevToolsSolution={visibleWcagReport.list.all}
          selectedIssuesId={selectedIssuesId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #e4e7eb;
  padding: 7px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    align-items: flex-start;
  }
`;

const FilterSelectDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin-top: 4%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const JiraSelectDropdownWrapper = styled.div`
  width: 191px;
`;

const CustomFormControl = MuiStyled(FormControl)(() => ({
  [`&.${selectClasses.outlined}`]: {
    borderColor: '#454284',
    borderWidth: '1.5px',
  },
  '& fieldset': {
    border: '1px solid rgb(216, 216, 216)',
  },
  '& .input-label, & .select-label': {
    fontSize: '13px',
  },
}));
