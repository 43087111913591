import React from 'react';
import { calculateAccessibilityScore } from '@equally-ai-front/common/src/lib/accessibility-report';
import { useMemo } from 'react';
import styled from 'styled-components';
import { PartiallyCompliant } from './partially-compliant';
import { FullyCompliant } from './fully-compliant';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { ACCESSIBILITY_MAX_SCORE } from '../../utils/constants';
import { ScanTotals } from '@equally-ai-front/common/src/types/scans';

interface AccessilitySummaryProps {
  report: ReportData;
  loading: boolean;
  scanImageUrl: string;
  isWidgetAppeared: boolean;
}

export const AccessilitySummary = (props: AccessilitySummaryProps) => {
  const { report, loading, scanImageUrl, isWidgetAppeared } = props;

  const accessibilityScore = useMemo(() => {
    const scanTotals: ScanTotals = {
      totalErrors: report.list.basic.length,
      totalNotices: report.list.potential.length,
      totalWarnings: report.list.advanced.length,
    };

    return calculateAccessibilityScore([scanTotals]) || 0;
  }, [report.list]);

  return (
    <Container>
      {isWidgetAppeared || accessibilityScore > ACCESSIBILITY_MAX_SCORE ? (
        <FullyCompliant
          loading={loading}
          issues={report.list.all}
          scanImageUrl={scanImageUrl}
        />
      ) : (
        <PartiallyCompliant
          loading={loading}
          issues={report.list.all}
          scanImageUrl={scanImageUrl}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
