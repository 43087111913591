import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TypographyH4 } from '../../../common/styled';
import { AddDomainLine } from '../../AddDomainLine';
import { ProductDescription } from './product-description';
import {
  ActionButton,
  FormContainer,
  FormHeader,
  ModalContentContainer,
  SubtitleText,
} from './styled';
import styled from 'styled-components';
import { Box } from '@mui/material';

interface AddDomainsFormProps {
  isLoading: boolean;
  handleInputChange: (value: string, index: number) => void;
  addDomains: () => void;
  domainsToAdd: string[];
  domains: any[];
  type: 'widget' | 'developer' | 'flowy' | 'service';
  setModalStep: () => void;
}

export const AddDomainsForm = ({
  domainsToAdd,
  handleInputChange,
  isLoading,
  domains,
  addDomains,
  setModalStep,
}: AddDomainsFormProps) => {
  const isEmptyDomain = domainsToAdd.every((domain) => !domain);

  const handleAddDomains = () => {
    if (isLoading || isEmptyDomain) {
      return;
    }
    addDomains();
  };
  return (
    <ModalContentContainer>
      <FormContainer>
        <FormHeader>
          <Box mt="35px" />
          <TypographyH4>Add your website(s)</TypographyH4>
          <SubtitleText>
            Every website added gets a 7-day free trial. You can skip the trial
            and purchase a license directly in the next step.
          </SubtitleText>
          <DomainLineContainer>
            {domainsToAdd.map((_, i) => (
              <AddDomainLine
                index={i}
                onInputChange={(value, index) =>
                  handleInputChange(value, index)
                }
              />
            ))}
          </DomainLineContainer>
        </FormHeader>

        <ActionButton
          color="primary"
          variant="contained"
          disabled={isLoading || isEmptyDomain}
          onClick={handleAddDomains}
        >
          <span>
            {(domains || []).length > 0
              ? 'Start free trial'
              : 'Start Free Plan'}
          </span>
        </ActionButton>
      </FormContainer>
      <BackButton color="primary" variant="text" onClick={setModalStep}>
        <ArrowBackIcon /> Back
      </BackButton>
    </ModalContentContainer>
  );
};

const BackButton = styled(ActionButton)`
  position: absolute;
`;

const DomainLineContainer = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px;
  max-height: 180px;
`;
