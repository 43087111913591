import React from 'react';
import styled from 'styled-components';
import { Box } from '@mui/material';

interface ScanReportLayoutProps {
  children: any[];
  emptyContentText?: string;
}

export const ScanReportLayout = ({
  children,
  emptyContentText,
}: ScanReportLayoutProps) => {
  return (
    <Wrapper>
      {children.length > 0 ? (
        children.map((child, idx) => (
          <div key={`scan-report-element-${idx}`}>{child}</div>
        ))
      ) : (
        <Box
          sx={{
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            height: '50px',
          }}
        >
          {emptyContentText || 'No content'}
        </Box>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 100px;
`;
