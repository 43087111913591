import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  InfoContent,
  MainHeader,
} from '../../../components/flowy-components/components/common';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ScansTable } from './scan-table/scans-table';
import { Box, CircularProgress, Typography } from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import moment from 'moment';
import { pageScansDataMap, rowData, rowMap } from '../const';
import { RootState } from '../../../store';
import { GroupScansSummary } from './group-scans-summary/group-scans-summary';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { fetchAllIssues } from '../../../components/compliance-manager/store/slices/issues-slice';
import { setApiError } from '../../../store/actions';

const getLastScan = (data: Scan[]): Scan | null => {
  if (data.length === 0) return null;

  const mostRecentScan = data.reduce((prev, current) => {
    const prevDate = new Date(prev.updated_at);
    const currentDate = new Date(current.updated_at);
    return currentDate > prevDate ? current : prev;
  });
  return mostRecentScan;
};

export const GroupPageScans = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const searchParams = new URLSearchParams(location.search);
  const currentBusiness = useSelector(
    (state: any) => state.business.currentBusiness,
  );
  const [scans, setScans] = useState<any[]>([]);

  const { history } = useSelector((state: RootState) => state.scanHistory);
  const { loading: isLoading } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const latestScan = useMemo(() => {
    const sortedPageScans = [...scans].sort((a, b) => {
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
    return sortedPageScans[0];
  }, [scans]);

  const fetchGroupScanIssues = useCallback(
    async (groupScans: Scan[]) => {
      if (!currentBusiness || groupScans.length === 0) return;

      const scanIds = groupScans.map((scan) => scan.id);
      const response: any = (
        await dispatch(
          fetchAllIssues({
            businessId: currentBusiness.id,
            scanIds,
          }),
        )
      ).payload;

      if (!response.isSuccess) {
        dispatch(setApiError(response.error));
      }
    },
    [currentBusiness, dispatch],
  );

  const scanGroups = useSelector(
    (state: RootState) => state.scanHistory.scanGroups,
  );

  const groupScanId = searchParams.get('group_scan_id');
  const { domainId } = useParams();

  const lastScanData = useMemo(() => getLastScan(history), [history]);

  useEffect(() => {
    if (!groupScanId || !domainId || !currentBusiness) {
      navigate(-1);
      return;
    }
    const groupScans = history.filter(
      (scan) => scan.group_id === parseInt(groupScanId, 10),
    );
    fetchGroupScanIssues(groupScans);
    setScans(groupScans);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigate,
    groupScanId,
    currentBusiness,
    dispatch,
    domainId,
    fetchGroupScanIssues,
  ]);

  return (
    <div>
      <HeaderWrapper>
        <div>
          {groupScanId && (
            <MainHeader>{scanGroups[groupScanId].name} scans</MainHeader>
          )}
          <InfoContent>
            Manage your website&apos;s scan, all in one place
          </InfoContent>
        </div>
        {/* {latestScan && groupScanId && ( */}
        {/*  <MainContentHeader */}
        {/*    performAudit={onAudit} */}
        {/*    currentScan={{ ...latestScan }} */}
        {/*  /> */}
        {/* )} */}
        {lastScanData && (
          <Typography>
            Last Scan:&nbsp;
            {moment(lastScanData.updated_at).format('DD MMM YYYY hh:mma')}
          </Typography>
        )}
      </HeaderWrapper>
      <GroupScansSummary scans={scans} />
      {isLoading && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '30vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Box mt="20px" />
      {!isLoading && (
        <ScansTable
          headingData={rowData}
          headingMap={rowMap}
          data={scans}
          dataMap={pageScansDataMap}
        />
      )}
    </div>
  );
};

const HeaderWrapper = MuiStyled(Box)`
  display: flex;
  justify-content: space-between;
`;
