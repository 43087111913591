import { selectDismissedIssues } from '@equally-ai-front/common/src/redux/developer-slice/selector';
import {
  Issue,
  IssueSeverity,
  IssueStatusEnum,
  IssueStatuses,
} from '@equally-ai-front/common/src/types/issues';
import { getIssuesCount } from '@equally-ai-front/common/src/utils';
import {
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import {
  setIssueSeverityFilter,
  setIssueStatuses,
  setReportType,
  setNativeHTMLElementsFilter,
} from '../../../store/dashboard-slice/dashboard';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {
  DashboardFilterStatus,
  DashboardReportType,
} from '../../../store/dashboard-slice/types';
import { FilterOption, FilterSelect } from './filter-select';
import { setApiError } from '../../../store/actions';
import { CreateJiraTaskPopup } from '../../../components/create-jira-task/popup';
import { JiraSelectDropdown } from '../../../components/create-jira-task/jira-select-dropdown';
import { nativeHTMLElementsSelectOptions } from '../const';
import { RootState } from '../../../store';

enum DROPDOWN_OPTIONS {
  CreateJiraIssues = 'Create Jira Issues',
}

const severityOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'basic',
    label: 'Critical',
  },
  {
    value: 'advanced',
    label: 'Potential',
  },
  {
    value: 'potential',
    label: 'Best practice',
  },
];

const groupByOptions: FilterOption[] = [
  {
    value: 'html',
    label: 'HTML Elements',
  },
  {
    value: 'wcag',
    label: 'WCAG',
  },
  {
    value: 'recommendation',
    label: 'Recommendations',
  },
];
const statusesOptions: FilterOption[] = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'dismissed',
    label: 'Dismissed',
  },
];
const issuesGroupType = ['wcag', 'html', 'recommendation'];

interface FiltersProps {
  activeFilter: DashboardFilterStatus;
  severity: IssueSeverity | 'all';
  reportType: DashboardReportType;
  disableFilterSelect: boolean;
  currentIssueStatus: IssueStatuses;
  selectedIssuesId: Record<number, boolean>;
  hasJiraSettingsPermission: boolean;
  visibleWcagReport: ReportData;
  issues: Issue[];
}

interface NativeElementSelectOption {
  label: string;
  key: string;
}

export const Filters = (props: FiltersProps) => {
  const {
    currentIssueStatus,
    severity,
    reportType,
    disableFilterSelect,
    selectedIssuesId,
    hasJiraSettingsPermission,
    visibleWcagReport,
    issues,
  } = props;

  const dispatch = useDispatch();
  const [showCreateJiraIssuesPopup, setShowCreateJiraIssuesPopup] =
    useState(false);
  const handleToggleCreateJiraIssuesPopup = () => {
    setShowCreateJiraIssuesPopup(!showCreateJiraIssuesPopup);
  };
  const [selectedOption, setSelectedOption] = useState<string>(
    DROPDOWN_OPTIONS.CreateJiraIssues,
  );
  const nativeHTMLElementsFilter = useSelector(
    (state: RootState) => state.dashboard.nativeHTMLElementsFilter,
  );
  const [selectedOptions, setSelectedOptions] = useState<
    NativeElementSelectOption[]
  >(nativeHTMLElementsSelectOptions);

  const OPTIONS = [
    {
      label: DROPDOWN_OPTIONS.CreateJiraIssues,
      icon: (
        <CancelPresentationIcon
          sx={{ height: '18px', width: '18px', marginRight: '5px' }}
        />
      ),
      handleClickAction: () => {
        if (Object.entries(selectedIssuesId).length === 0) {
          dispatch(setApiError('Select an issue before proceeding!'));
          return;
        }
        handleToggleCreateJiraIssuesPopup();
      },
    },
  ];

  const selectActiveFilter = (filterType: DashboardFilterStatus): void => {
    dispatch(setIssueStatuses(filterType));
  };
  const handleSeverityChange = (filterType: IssueType): void => {
    dispatch(setIssueSeverityFilter(filterType));
  };
  const handleReportTypeChange = (newReportType: DashboardReportType): void => {
    dispatch(setIssueStatuses('all'));
    dispatch(setIssueSeverityFilter('all'));
    dispatch(setReportType(newReportType));
  };
  const handleChange = (
    evt: React.SyntheticEvent,
    newValue: NativeElementSelectOption[],
  ) => {
    const selectedKeys = newValue.map(({ key }) => key);
    dispatch(setNativeHTMLElementsFilter(selectedKeys));
    setSelectedOptions(newValue);
  };

  return (
    <Wrapper>
      <FilterSelectDiv>
        <FilterSelect
          id="groupBy"
          options={groupByOptions}
          value={reportType}
          setValue={handleReportTypeChange}
          formInputLabel="Group By"
          marginRight="15px"
          disabled={disableFilterSelect}
        />
        {issuesGroupType.includes(reportType) && (
          <FilterSelect
            id="issue-statuses"
            options={statusesOptions}
            value={currentIssueStatus}
            setValue={selectActiveFilter}
            formInputLabel="Status"
            marginRight="15px"
            disabled={false}
          />
        )}
        {issuesGroupType.includes(reportType) && (
          <FilterSelect
            id="severity"
            options={severityOptions}
            value={severity}
            setValue={handleSeverityChange}
            formInputLabel="Severity"
            marginRight="15px"
            disabled={disableFilterSelect}
          />
        )}
        {/* <Autocomplete
          multiple
          id="nativeHtmlMultiSelect"
          key="nativeHtmlMultiSelect"
          options={nativeHTMLElementsSelectOptions}
          getOptionLabel={(option) => option.label}
          disableCloseOnSelect
          value={selectedOptions}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Native HTML Elements"
              placeholder={
                selectedOptions.length ===
                nativeHTMLElementsSelectOptions.length
                  ? ''
                  : 'Select'
              }
              InputProps={{
                ...params.InputProps,
                sx: {
                  height: '36px',
                  padding: '0 12px',
                  fontSize: '14px',
                  '& .MuiOutlinedInput-input': {
                    display:
                      selectedOptions.length ===
                      nativeHTMLElementsSelectOptions.length
                        ? 'none'
                        : 'flex',
                  },
                  '& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
                    fontSize: '17px',
                  },
                },
              }}
            />
          )}
          sx={{
            marginBottom: '10px',
            width: '100%',
            minWidth: '150px',
            maxWidth: '90%',
            '& .MuiInputLabel-root': {
              backgroundColor: '#ffffff',
              fontSize: '13px',
              padding: '0 5px',
              top: '1px',
            },
            '& .MuiAutocomplete-tag': {
              margin: '1px 1px',
              fontSize: '12px',
              maxWidth: '100%',
            },
            '& .MuiChip-root': {
              height: '24px',
              '& .MuiChip-deleteIcon': {
                fontSize: '18px',
              },
            },
            '& .MuiOutlinedInput-root': {
              height: '45px',
              padding: '5px 0 5px 8px',
              overflowY: 'auto',
              border: '1px solid rgb(216, 216, 216)',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selectedOptions.some(
                  (selectedOption) => selectedOption.label === option.label,
                )}
              />
              {option.label}
            </li>
          )}
        /> */}
      </FilterSelectDiv>
      {hasJiraSettingsPermission && (
        <JiraSelectDropdownWrapper>
          <JiraSelectDropdown
            isDisabled={visibleWcagReport.list.all.length === 0}
            options={OPTIONS}
            handleSelectOption={(opt) => setSelectedOption(opt)}
            selectedOption={selectedOption}
          />
        </JiraSelectDropdownWrapper>
      )}
      {showCreateJiraIssuesPopup && (
        <CreateJiraTaskPopup
          isOpen={showCreateJiraIssuesPopup}
          handleClose={handleToggleCreateJiraIssuesPopup}
          issues={issues}
          issuesDevToolsSolution={visibleWcagReport.list.all}
          selectedIssuesId={selectedIssuesId}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1.5px solid #e4e7eb;
  padding: 7px 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 20px;
    align-items: flex-start;
  }
`;

const FilterSelectDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin-top: 4%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const JiraSelectDropdownWrapper = styled.div`
  width: 191px;
`;
