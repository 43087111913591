import styled from '@emotion/styled';
import {
  Box,
  Grid,
  useMediaQuery as useMaterialUiMediaQuery,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Issue, Scan } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { AuditReportView } from './audit-report-view';
import { AuditInputForm } from './audit-input-form';
import { AuditViewHeader } from './audit-view-header';
import DesktopAuditReportViewSkeleton from '../../assets/desktop-audit-report-view-skeleton.svg';
import MobileAuditReportViewSkeleton from '../../assets/mobile-audit-report-view-skeleton.svg';
import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { AuditReportHeader } from './audit-report-header';

interface AuditViewProps {
  handleAudit: (
    url: string,
    cb?: (url: string, issues: Issue[]) => void,
  ) => void;
  loading: boolean;
  isWidgetAppeared: boolean;
  report: ReportData;
  scanImageUrl: string;
  currentScan: Scan | null;
}

export const AuditView = (props: AuditViewProps) => {
  const {
    handleAudit,
    loading,
    report,
    isWidgetAppeared,
    scanImageUrl,
    currentScan,
  } = props;
  const isMD = useMaterialUiMediaQuery((theme: any) =>
    theme.breakpoints.up('md'),
  );
  const isMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const [isInputAllowed, setIsInputAllowed] = useState(false);
  const [auditUrl, setAuditUrl] = useState('');
  const [loadingAuditReport, setLoadingAuditReport] = useState(false);

  const handleAuditUrlChange = (
    evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAuditUrl(evt.target.value);
  };

  const onAudit = async (url: string) => {
    setLoadingAuditReport(true);
    await handleAudit(url);
  };

  const handleShowScanInput = () => {
    setIsInputAllowed(false);
  };

  useEffect(() => {
    if (currentScan?.status === 'SUCCESS') {
      setIsInputAllowed(true);
    }
  }, [currentScan]);

  return (
    <>
      <Grid
        container
        spacing={3}
        wrap="nowrap"
        style={{
          width: '100%',
          overflow: 'hidden',
          marginLeft: 0,
        }}
      >
        <Grid
          item
          sm={12}
          md
          style={{ width: '100%', overflow: 'hidden', paddingLeft: 0 }}
        >
          <LayoutMain>
            <AuditViewWrapper>
              {isInputAllowed && currentScan && !loadingAuditReport ? (
                <AuditReportHeader
                  currentScan={currentScan}
                  handleShowScanInput={handleShowScanInput}
                />
              ) : (
                <AuditInputForm
                  onAudit={onAudit}
                  label={'Url'}
                  loading={loading}
                  handleAuditUrlChange={handleAuditUrlChange}
                  auditUrl={auditUrl}
                />
              )}
              {(report.websiteUrl || loading || loadingAuditReport) && (
                <>
                  <Box mt={isMD ? '32px' : '24px'} />
                  <AuditViewHeader
                    isWidgetAppeared={isWidgetAppeared}
                    report={report}
                    loading={loading || loadingAuditReport}
                    scanImageUrl={scanImageUrl}
                  />
                </>
              )}
            </AuditViewWrapper>

            {(loading || loadingAuditReport) && (
              <AuditReportViewSkeletonWrapper className="skeleton skeleton-text">
                <AuditReportViewSkeletonImg
                  src={
                    isMobile
                      ? MobileAuditReportViewSkeleton
                      : DesktopAuditReportViewSkeleton
                  }
                  alt=""
                />
              </AuditReportViewSkeletonWrapper>
            )}

            {!loading && report && (
              <AuditReportView
                auditReport={report}
                url={report.websiteUrl || ''}
                loadingAuditReport={loadingAuditReport}
                setLoadingAuditReport={setLoadingAuditReport}
              />
            )}
          </LayoutMain>
        </Grid>
      </Grid>
    </>
  );
};

const LayoutMain = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuditViewWrapper = styled.div`
  background-color: #f5f7fa;
  border-radius: 16px;
  padding: 5vh 0;
  width: 100%;
  max-width: 85%;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 2vh 0;
    margin-top: 5vh;
  }
`;

const AuditReportViewSkeletonWrapper = styled.div`
  margin-top: 5vh;
  max-width: 85%;
`;

const AuditReportViewSkeletonImg = styled.img`
  width: 100%;
  height: auto;
`;
