import {
  AccessibilityAccordion,
  AccordionState,
  useAccessibilityAccordion,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { AccessibilityIssueDetails } from '@equally-ai-front/common/src/components/accessiblity-issue-details/AccessibilityIssueDetails';
import { groupAccessibilityIssuesByTitle } from '@equally-ai-front/common/src/lib';
import { GetSavedGptSuggestionsArgType } from '@equally-ai-front/common/src/redux/developer-slice/types';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { getAssetReactAppUrl } from '@equally-ai-front/common/src/utils';
import { Divider } from './scan-report-section-item';
import {
  DevtoolsSolution,
  IssueType,
  ReportData,
} from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { Box, Paper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  ScanReportSectionItem,
  ScanReportItemDetailsProps,
} from './scan-report-section-item';
import { useRef, useEffect } from 'react';
import { useScroll } from '@equally-ai-front/common/src/contexts/scroll-provider';
import { IssuesSummary } from '@equally-ai-front/common/src/components/issues-summary';

interface ScanReportSectionProps {
  reportSectionTitle: string;
  scanItems: DevtoolsSolution[];
  activeItemId: string;
  devDomain?: DevDomain;
  wcagReport: ReportData;
  issueType: IssueType;
  toggleSectionItem: (
    id: string,
    payloadToGetSavedGptSuggestion: GetSavedGptSuggestionsArgType,
  ) => void;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  accordionId: string;
  scanReportItemDetails?: React.ComponentType<ScanReportItemDetailsProps>;
  setSuccessMessage: (message: string) => void;
  hideHowToFix?: boolean;
  selectedIssuesId: Record<number, boolean>;
  setSelectedIssuesId: React.Dispatch<
    React.SetStateAction<Record<number, boolean>>
  >;
  jiraSettings?: Record<string, any>;
}

interface IssueSummaryCount {
  error: number;
  warning: number;
  notice: number;
}

export const ScanReportSection = ({
  reportSectionTitle,
  scanItems,
  devDomain,
  wcagReport,
  issueType,
  toggleSectionItem,
  activeItemId,
  accordionState,
  handleAccordionToggle,
  accordionId,
  scanReportItemDetails,
  setSuccessMessage,
  hideHowToFix,
  selectedIssuesId,
  setSelectedIssuesId,
  jiraSettings,
}: ScanReportSectionProps) => {
  const { registerSection } = useScroll();
  const {
    accordionState: accordionToggleState,
    handleAccordionToggle: handleToggleTheAccordion,
  } = useAccessibilityAccordion();
  const reportSectionRef = useRef<HTMLDivElement | null>(null);
  // const calculateImpactLevelColors = (
  //   impactLevel: string
  // ): ImpactLevelColors => {
  //   let impactLevelBg: string;
  //   let impactLevelColor: string;

  //   if (impactLevel === "high") {
  //     impactLevelBg = StyleConstants.COLOR_ERROR_1;
  //     impactLevelColor = StyleConstants.COLOR_ERROR_2;
  //   } else if (impactLevel === "warning") {
  //     impactLevelBg = StyleConstants.COLOR_WARNING_1;
  //     impactLevelColor = StyleConstants.COLOR_WARNING_2;
  //   } else {
  //     impactLevelBg = StyleConstants.COLOR_SUCCESS_1;
  //     impactLevelColor = StyleConstants.COLOR_SUCCESS_2;
  //   }

  //   return {
  //     bgColor: impactLevelBg,
  //     color: impactLevelColor,
  //   };
  // };

  const handleToggleAccordion = () => {
    handleAccordionToggle(accordionId);
  };

  const issuesGroupedByTitle = useMemo(
    () => groupAccessibilityIssuesByTitle(scanItems),
    [scanItems],
  );

  const issuesSummaryCount: IssueSummaryCount = useMemo(() => {
    const counts: IssueSummaryCount = { error: 0, warning: 0, notice: 0 };
    scanItems.forEach(({ type }) => {
      const issueType = type as keyof IssueSummaryCount;
      if (counts[issueType] !== undefined) {
        counts[issueType]++;
      }
    });
    return counts;
  }, [scanItems]);

  useEffect(() => {
    if (reportSectionRef.current) {
      registerSection(
        reportSectionTitle,
        reportSectionRef.current,
        handleToggleAccordion,
      );
    }
  }, [reportSectionTitle, registerSection, handleToggleAccordion]);

  return (
    <Container
      variant="outlined"
      id={reportSectionTitle}
      ref={reportSectionRef}
    >
      <AccessibilityAccordion
        title={reportSectionTitle}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        accordionHeader={
          <>
            <AccordionHeaderContainer>
              <HeaderText>{reportSectionTitle}</HeaderText>
              <IssuesSummary
                error={issuesSummaryCount.error}
                warning={issuesSummaryCount.warning}
                notice={issuesSummaryCount.notice}
              />
            </AccordionHeaderContainer>
            <ArrowBox>
              <ArrowIcon
                src={getAssetReactAppUrl('/chevron-down-black.svg')}
                alt="arrow down icon"
                shouldRotate={accordionState[accordionId]}
              />
            </ArrowBox>
          </>
        }
      >
        <Divider />
        {Object.entries(issuesGroupedByTitle).map(
          ([groupTitle, issues], index) => {
            const {
              issueDescription,
              message,
              howToFixTheIssue,
              relatedWCAGLink,
            } = issues[0];
            const handleToggleGroupTitleAccordion = () => {
              handleToggleTheAccordion(groupTitle);
            };

            return (
              <IssuesGroupWrapper key={index}>
                <AccessibilityAccordion
                  title={groupTitle}
                  accordionId={groupTitle}
                  accordionState={accordionToggleState}
                  handleToggleAccordion={handleToggleGroupTitleAccordion}
                  accordionHeader={
                    <HeaderWrapper>
                      <TitleAndIcon>
                        <HeaderTitle>{groupTitle}</HeaderTitle>
                        <ArrowBox>
                          <ArrowIcon
                            src="/chevron-down-black.svg"
                            alt="arrow down icon"
                            shouldRotate={accordionState[groupTitle]}
                          />
                        </ArrowBox>
                      </TitleAndIcon>
                    </HeaderWrapper>
                  }
                >
                  <Divider />
                  <AccessibilityIssueDetails
                    issueDescriptionId={index.toString()}
                    relatedWCAGLink={relatedWCAGLink}
                    issueDescriptionContent={
                      <>
                        <IssueDescriptionDiv>
                          <IssueDescription>
                            <BodyContent>{issueDescription}</BodyContent>
                          </IssueDescription>
                        </IssueDescriptionDiv>
                      </>
                    }
                    howToFixContent={
                      <IssueDetailsDiv>
                        <HowToFix>
                          <ul>
                            {message !== howToFixTheIssue[0] && (
                              <li>
                                <BodyContent>{message}</BodyContent>
                              </li>
                            )}
                            {howToFixTheIssue.length > 0 &&
                              howToFixTheIssue.map((fix, howToFixIdx) => {
                                const howToFixKey = `${fix}-${howToFixIdx}`;
                                return (
                                  <li key={howToFixKey}>
                                    <BodyContent>{fix}</BodyContent>
                                  </li>
                                );
                              })}
                          </ul>
                        </HowToFix>
                      </IssueDetailsDiv>
                    }
                    hideHowToFix={hideHowToFix}
                  />
                  <RelatedIssues>
                    <IssuesTitle>Elements ({issues.length})</IssuesTitle>
                    {issues.map((item, idx) => (
                      <ScanReportSectionItemContainer>
                        <ScanReportSectionItem
                          key={idx}
                          title={item.selector ? item.selector : item.title}
                          accordionId={item.id.toString()}
                          accordionState={accordionToggleState}
                          handleAccordionToggle={handleToggleTheAccordion}
                          level={item.level}
                          sectionItemId={item.id.toString()}
                          activeItemId={activeItemId}
                          sectionItem={item}
                          identifier={idx === 0 ? 'firstIssue' : idx.toString()}
                          wcagReport={wcagReport}
                          issueIndex={idx}
                          issueType={issueType}
                          devDomain={devDomain}
                          toggleSectionItem={toggleSectionItem}
                          scanReportItemDetails={scanReportItemDetails}
                          setSuccessMessage={setSuccessMessage}
                          selectedIssuesId={selectedIssuesId}
                          setSelectedIssuesId={setSelectedIssuesId}
                          jiraSettings={jiraSettings}
                        />
                      </ScanReportSectionItemContainer>
                    ))}
                  </RelatedIssues>
                </AccessibilityAccordion>
                {Object.entries(issuesGroupedByTitle).length - 1 > index && (
                  <Divider />
                )}
              </IssuesGroupWrapper>
            );
          },
        )}
      </AccessibilityAccordion>
    </Container>
  );
};

const Container = styled(Paper)`
  border: 2px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;

  > div > h3 {
    padding: 0 8px;
  }

  @media (pointer: none), (pointer: coarse) {
    margin: 0 0 20px;
  }
`;

const IssuesGroupWrapper = styled.div`
  > div > h3 {
    padding: 0 8px;
  }
`;

const AccordionHeaderContainer = styled(Box)`
  width: calc(100% - 44px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 12px;
`;

const HeaderText = styled(Typography)`
  font-weight: 500;
  font-size: 17px;
  text-align: left;
`;

const HeaderTitle = styled(HeaderText)`
  font-size: 14px;
  font-weight: 500;
  margin-right: 12px;
  max-width: calc(100% - 44px);
`;

export const ArrowBox = styled.div`
  border: 2px solid #e4e7eb;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ArrowIconProps {
  shouldRotate: boolean;
}

export const ArrowIcon = styled.img<ArrowIconProps>`
  width: 80%;
  transform: ${(props) => (props.shouldRotate ? 'rotate(180deg)' : 'none')};
  transition: transform 100ms;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleAndIcon = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const IssueDescriptionDiv = styled.div``;

const IssueDescription = styled.div``;

const BodyContent = styled.div`
  font-size: 13px;
  color: rgba(0, 10, 20, 0.85);
`;

const HowToFix = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 5vh;

  @media screen and (max-width: 650px) {
    margin-left: 0;
    margin-top: 2vh;
  }
`;

const IssueDetailsDiv = styled.div``;
const ScanReportSectionItemContainer = styled(Box)`
  margin: 10px 0;
`;

const IssuesTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  padding-top: 16px;
`;

const RelatedIssues = styled.div`
  padding: 0 32px;
`;
