import { Typography, Box } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div``;

export const SvgWrapper = styled.div`
  width: 100%;
  max-width: 5.5vw;

  @media screen and (min-width: 1300px) {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1150px) {
    max-width: 100vw;
    display: flex;
    justify-content: center;
  }
`;

export const TypographyContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  @media screen and (max-width: 1030px) {
    margin-left: 2vw;
  }

  @media screen and (max-width: 1150px) {
    margin-left: 0;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
  }
`;

export const ScanHeaderText = styled(Typography)`
  font-weight: 500;
  margin-top: 6px;

  @media screen and (max-width: 1150px) {
    white-space: nowrap;
    font-size: 28.8px;
    margin-top: -13px;
  }

  @media screen and (max-width: 768px) {
    font-size: 22.4px;
  }
`;

export const ScanText = styled(Typography)`
  margin: 0.3rem 0 2.5rem;
  font-size: 0.95rem;

  @media screen and (max-width: 1150px) {
    margin-bottom: 0;
    text-align: left;
  }
`;

export const ScanHeader = styled(Box)`
  display: flex;

  @media screen and (max-width: 1150px) {
    align-items: center;
    width: 100%;
  }
`;

export const SkeletonImageWrapper = styled.div``;
export const SkeletonImage = styled.img`
  @media screen and (max-width: 1150px) {
    width: 100%;
  }
`;

export const IssueSummaryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IssueSummary = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 90%;

  @media screen and (max-width: 1150px) {
    flex-direction: column-reverse;
    position: relative;
  }
`;

export const IssueSummaryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 360px);
  margin-left: 40px;

  @media screen and (max-width: 1150px) {
    max-width: 100%;
    margin-left: 0;
  }
`;

export const IssueSummaryContent = styled.div`
  display: flex;

  @media screen and (max-width: 1150px) {
    margin-left: 0;
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
  }
`;

export const PdfDownloadButtonContainer = styled.div`
  @media screen and (max-width: 1150px) {
    margin-left: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
`;
