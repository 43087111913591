import styled from '@emotion/styled';
import DomainsAPI from '@equally-ai-front/common/src/api/domains';
import PlansService from '@equally-ai-front/common/src/api/plans-service';
import { LoadingView } from '@equally-ai-front/common/src/components/loading-view';
import { getWidgetDomains } from '@equally-ai-front/common/src/redux/user-products-slice/user-products';
import { Box, Theme } from '@mui/material';
import Popover from '@mui/material/Popover';
import { withStyles } from '@mui/styles';
import React, { CSSProperties, MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatExpiration } from '../../../lib/date';
import { setApiError, setApiSuccessMessage } from '../../../store/actions';
import { PrimaryMobileButton } from '../../redesign/Controls';
import { ProductType } from '../product-type-chip';
import { usePlans } from '../../../providers/plans-context';

interface ActionButtonProp {
  showQuickActions: boolean;
  hideActionButton: (tableRow: { [key: string]: any }) => boolean;
  handleSelectPlan: (event: React.MouseEvent<HTMLElement>, row: any) => void;
  actionLabel: string;
  allowAttach: boolean;
  allowDetach: boolean;
  flowyAction?: (event: MouseEvent, row: any) => void;
  showFlowyAction: (row: any) => boolean;
  actionButtonStyle?: CSSProperties;
  ariaLabelledby?: string;
}

interface IActionButtons {
  row: any;
  actionButtonProp: ActionButtonProp;
}

const handleCopyToClipboard = async (value: string) => {
  if ('clipboard' in navigator && 'writeText' in navigator.clipboard) {
    await navigator.clipboard.writeText(value);
  } else {
    document.execCommand('copy', true, value);
  }
};

const INCLUDED_DOMAIN_TYPES_FOR_COPY = ['widget', 'flowy'];

export const ActionButtons = ({ row, actionButtonProp }: IActionButtons) => {
  const {
    showQuickActions,
    hideActionButton,
    handleSelectPlan,
    actionLabel,
    allowAttach,
    allowDetach,
    actionButtonStyle,
    ariaLabelledby,
    flowyAction,
    showFlowyAction,
  } = actionButtonProp;
  const dispatch = useDispatch();

  const { currentBusiness } = useSelector((state: any) => ({
    currentBusiness: state.business.currentBusiness,
  }));
  const { activePlans: plansData } = usePlans();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [loadingQuickActions, setLoadingQuickActions] = useState(false);
  const [sampleCode, setSampleCode] = useState<{
    code: string;
    apiKey: string;
  }>({
    code: '',
    apiKey: '',
  });
  const [loadingSampleCode, setLoadingSampleCode] = useState(false);
  const { name, id, plan_id: planId, archived } = row;

  const handleDomainPopoverClick = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${name}-popover` : undefined;
  const loadingText = loadingSampleCode
    ? 'Getting your code snippet ready...'
    : 'Getting your domain ready...';

  const handleAttachDomainToPlan = async (
    event: MouseEvent,
    productType: ProductType,
  ) => {
    event.stopPropagation();
    setLoadingQuickActions(true);

    const plan = plansData.find(
      (planData: any) =>
        planData.is_active &&
        planData.quantity > 0 &&
        planData.plan_type === productType &&
        formatExpiration(planData.expiration) !== 'Expired',
    );

    if (!plan) {
      dispatch(setApiError('Plan quantity is zero'));
      setLoadingQuickActions(false);
      return;
    }

    const response = await PlansService.attachMultipleDomainsToPlan({
      planID: plan.id,
      businessID: currentBusiness.id,
      type: 'widget',
      domainIDs: [id],
    });

    if (response.isSuccess) {
      dispatch(setApiSuccessMessage(response.data));
      // @ts-ignore
      dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
    } else {
      dispatch(setApiError(response.error));
    }
    setLoadingQuickActions(false);
    setAnchorEl(null);
  };

  const handleDetachDomainFromPlan = async (event: MouseEvent) => {
    event.stopPropagation();
    setLoadingQuickActions(true);
    const response = await PlansService.detachDomainFromPlan({
      businessID: currentBusiness.id,
      websiteID: id,
    });

    if (response.isSuccess) {
      dispatch(setApiSuccessMessage(response.data));
      // @ts-ignore
      dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
    } else {
      dispatch(setApiError(response.error));
    }
    setLoadingQuickActions(false);
    setAnchorEl(null);
  };

  const handleCopySnippetCode = async (
    event: MouseEvent,
    type: 'code' | 'apiKey',
  ) => {
    event.stopPropagation();
    let message = `${name} HTML code copied to clipboard!`;

    if (type === 'apiKey') {
      message = `${name} API key copied to clipboard`;
    }

    if (sampleCode[type]) {
      handleCopyToClipboard(sampleCode[type]);
      dispatch(setApiSuccessMessage(message));
      setAnchorEl(null);
      return;
    }

    setLoadingSampleCode(true);
    const {
      data: code,
      error,
      isSuccess,
    } = await DomainsAPI.getSampleCode(name, currentBusiness.id);

    setLoadingSampleCode(false);
    if (!isSuccess) {
      dispatch(
        setApiError(
          error?.response?.data?.message ||
            "Couldn't process your request at the moment",
        ),
      );
      return;
    }

    setSampleCode(code);
    handleCopyToClipboard(code[type]);
    dispatch(setApiSuccessMessage(message));
    setAnchorEl(null);
  };

  const handleCopyDomain = (event: MouseEvent) => {
    event.stopPropagation();
    handleCopyToClipboard(name);
    dispatch(setApiSuccessMessage(`${name} domain copied to clipboard!`));
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLDivElement>) => {
    // @ts-ignore
    const targetElement: HTMLElement = evt.target;
    if (evt.code === 'Space' || evt.code === 'Enter') {
      targetElement.click();
    }

    if (evt.code === 'Tab' && targetElement.hasAttribute('data-last-item')) {
      setAnchorEl(null);
    }
  };

  const handleFlowyAction = (event: any) => {
    if (flowyAction) {
      flowyAction(event, row);
    }
  };

  const handleArchiveDomain = async () => {
    setAnchorEl(null);
    const { isSuccess, error } = await DomainsAPI.updateDomain(
      currentBusiness.id,
      name,
      !archived,
    );

    if (isSuccess) {
      // @ts-ignore
      dispatch(getWidgetDomains({ businessId: currentBusiness.id }));
      const successMessage = archived
        ? `${name} has been removed from archive!`
        : `${name} has been archived!`;
      dispatch(setApiSuccessMessage(successMessage));
      return;
    }

    dispatch(setApiError(error));
  };

  const isActionButtonHidden = hideActionButton(row);
  const isFlowyButtonShown = showFlowyAction && showFlowyAction(row);

  const isFlowyButtonActive =
    isFlowyButtonShown && formatExpiration(row.expiration) !== 'Expired';

  return (
    <ButtonContainer>
      {isFlowyButtonActive && (
        <ManageButton
          onClick={handleFlowyAction}
          variant="contained"
          color="primary"
          aria-haspopup="dialog"
          disabled={!isFlowyButtonActive}
          style={{
            ...actionButtonStyle,
          }}
          aria-labelledby={ariaLabelledby}
        >
          Dashboard &#8250;
        </ManageButton>
      )}

      {!isActionButtonHidden && (
        <ManageButton
          onClick={(e) => handleSelectPlan(e, row)}
          variant="contained"
          color="primary"
          aria-haspopup="dialog"
          disabled={isActionButtonHidden}
          style={{
            ...actionButtonStyle,
          }}
          aria-labelledby={ariaLabelledby}
        >
          {actionLabel} &#8250;
        </ManageButton>
      )}

      {showQuickActions && (
        <EllipsisButton
          aria-label="Open quick actions"
          onClick={handleDomainPopoverClick}
          aria-expanded={Boolean(anchorEl)}
        >
          <EllipsisButtonText>...</EllipsisButtonText>
        </EllipsisButton>
      )}
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <PopoverContainer>
          {planId === 0 && allowAttach && (
            <PopverItem
              onClick={(event) => handleAttachDomainToPlan(event, row.type)}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              Link To Plan
            </PopverItem>
          )}
          {planId !== 0 && allowDetach && (
            <PopverItem
              onClick={(event) => handleDetachDomainFromPlan(event)}
              role="button"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              Detach From Plan
            </PopverItem>
          )}
          {INCLUDED_DOMAIN_TYPES_FOR_COPY.includes(row.type) && (
            <>
              <PopverItem
                onClick={(e) => handleCopySnippetCode(e, 'code')}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                Copy Snippet Code
              </PopverItem>
              <PopverItem
                onClick={(e) => handleCopySnippetCode(e, 'apiKey')}
                role="button"
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                Copy API Key
              </PopverItem>
            </>
          )}
          <PopverItem
            onClick={handleCopyDomain}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            data-last-item="true"
          >
            Copy Url
          </PopverItem>
          <PopverItem
            onClick={handleArchiveDomain}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            data-last-item="true"
          >
            {archived ? 'Remove from archive' : 'Archive'}
          </PopverItem>
        </PopoverContainer>
      </Popover>
      <LoadingView
        loadingText={loadingText}
        open={loadingQuickActions || loadingSampleCode}
        zIndex="2100"
      />
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 5px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 90%;
    justify-content: end;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: flex-end;
  }
`;

const ManageButton = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: `${(theme: Theme) => theme.palette.primary.main}`,
    fontSize: '13px',
    height: '30px',
    padding: '6px 12px',

    '@media (max-width: 600px)': {
      padding: '6px 8px',
      fontSize: '12px',
    },
  },
})(PrimaryMobileButton);

const EllipsisButton = styled(PrimaryMobileButton)({
  backgroundColor: 'transparent',
  padding: 0,
  maxWidth: 60,
  minWidth: 30,

  '@media (max-width: 600px)': {
    display: 'none',
  },
});

const EllipsisButtonText = styled.span`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
`;

const PopoverContainer = styled.div`
  margin: 10px 15px;
`;

const PopverItem = styled.div`
  padding: 5px;
  cursor: pointer;
`;
