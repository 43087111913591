import { Scan } from '@equally-ai-front/common/src/types/issues';
import {
  HIGH_IMPACT_MESSAGE,
  LOW_IMPACT_MESSAGE,
  MEDIUM_IMPACT_MESSAGE,
} from '@equally-ai-front/common/src/utils/constants';
import React from 'react';
import styled from 'styled-components';
import { ImpactLevelBadge } from '@equally-ai-front/common/src/components/impact-level-badge';
import { PrimaryButton } from '../styled-components';
import { getPageName } from '@equally-ai-front/common/src/lib';

interface DashboardHeaderProps {
  currentScan: Scan;
  handleShowScanInput: () => void;
}

export const AuditReportHeader = (props: DashboardHeaderProps) => {
  const { currentScan, handleShowScanInput } = props;

  return (
    <Wrapper>
      <Header>
        <HeadingText>{getPageName(currentScan)}</HeadingText>
        <NewScanButton
          color="primary"
          variant="contained"
          onClick={handleShowScanInput}
        >
          New Scan
        </NewScanButton>
      </Header>
      <ImpactLevels>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.error}
            iconSrc="/info-circle.svg"
            backgroundColor="#FF9999"
            color="#661F20"
            toolTipMessage={HIGH_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Critical</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.warning}
            iconSrc="/info-circle-red.svg"
            backgroundColor="#F2DF87"
            color="#6F321B"
            toolTipMessage={MEDIUM_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Potential</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
        <ImpactLevelRow>
          <ImpactLevelBadge
            alt=""
            impactNumber={currentScan.notice}
            iconSrc="/info-circle.svg"
            backgroundColor="#AEEAD3"
            color="#1E5643"
            toolTipMessage={LOW_IMPACT_MESSAGE}
          >
            <ImpactLevelLabel>Best Practice</ImpactLevelLabel>
          </ImpactLevelBadge>
        </ImpactLevelRow>
      </ImpactLevels>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 90%;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  @media (pointer: none), (pointer: coarse) {
    flex-direction: column;
  }
`;

const HeadingText = styled.h2`
  font-weight: 500;
  font-size: 40px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 70%;
  margin: 0;

  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    max-width: 150px;
    white-space: nowrap;
  }

  @media (pointer: none), (pointer: coarse) {
    max-width: 100%;
  }
`;

const ImpactLevels = styled.div`
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;

  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
`;

const ImpactLevelRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    margin-right: 15px;

    > div {
      padding-right: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ImpactLevelLabel = styled.span`
  display: inline-block;
  font-weight: 500;
  color: #000a14;
  font-size: 16px;
  margin-right: 4px;

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NewScanButton = styled(PrimaryButton)`
  height: 36px;
  padding: 6px 16px 6px 12px;
  border-radius: 8px;
`;
