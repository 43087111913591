import styled from '@emotion/styled';
import {
  AccessibilityAccordion,
  AccordionState,
} from '@equally-ai-front/common/src/components/accessible-components/accordion/accessibility-accordion';
import { Box, Grid, Paper, useMediaQuery } from '@mui/material';
import { gridClasses } from '@mui/material/Grid';
import isNil from 'lodash/isNil';
import React from 'react';
import { PillNoRadius } from '../scan-report/scan-report-section-item';
import { ArrowBox, ArrowIcon } from '../scan-report/scan-report-section';

interface IssueReportSectionProps {
  title: string;
  noticeIssueCount: number;
  warningIssueCount: number;
  criticalIssueCount: number;
  accordionId: string;
  accordionState: AccordionState;
  handleAccordionToggle: (accordionId: string) => void;
  children: React.ReactNode;
}

export const IssueReportSection = (props: IssueReportSectionProps) => {
  const {
    title,
    noticeIssueCount,
    warningIssueCount,
    criticalIssueCount,
    accordionId,
    accordionState,
    handleAccordionToggle,
    children,
  } = props;
  const isMobileDevice = useMediaQuery('(pointer:none), (pointer:coarse)');
  const handleToggleAccordion = () => {
    handleAccordionToggle(accordionId);
  };

  return (
    <Container variant="outlined">
      <AccessibilityAccordion
        title={title}
        accordionId={accordionId}
        accordionState={accordionState}
        handleToggleAccordion={handleToggleAccordion}
        accordionHeader={
          <AccordionHeaderContainer>
            <Grid container spacing={2}>
              <Grid item xs={12} sm>
                <Grid container>
                  <ArrowBox>
                    <ArrowIcon
                      src="/chevron-down-black.svg"
                      alt="arrow down icon"
                      shouldRotate={accordionState[accordionId]}
                    />
                  </ArrowBox>
                  <Box ml={isMobileDevice ? '16px' : '12px'} />
                  <HeaderText>
                    <TitleTypography>{title}</TitleTypography>
                  </HeaderText>
                </Grid>
              </Grid>

              <Grid item xs={12} sm="auto">
                <Grid container>
                  {!isNil(criticalIssueCount) && (
                    <PillNoRadius bgColor="#FF9999" color="#661F20">
                      {`${criticalIssueCount} Critical`}
                    </PillNoRadius>
                  )}
                  <Box ml="8px" />
                  {!isNil(warningIssueCount) && (
                    <PillNoRadius bgColor="#f2df87" color="#6f321b">
                      {`${warningIssueCount} Potential`}
                    </PillNoRadius>
                  )}
                  <Box ml="8px" />
                  {!isNil(noticeIssueCount) && (
                    <PillNoRadius bgColor="#454284" color="#ffffff">
                      {`${noticeIssueCount} Best practice`}
                    </PillNoRadius>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </AccordionHeaderContainer>
        }
      >
        {children}
      </AccessibilityAccordion>
    </Container>
  );
};

/** Styled Components */

const Container = styled(Paper)`
  border: 2px solid #e4e7eb;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;

  > div > h3 {
    padding: 0 8px;
  }

  @media (pointer: none), (pointer: coarse) {
    margin: 0 0 20px;
  }
`;

const HeaderText = styled.div``;

const TitleTypography = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
`;

const AccordionHeaderContainer = styled(Box)`
  width: 100%;

  > .${gridClasses.root} {
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
  }
`;
