import React from 'react';
import { FieldMap } from '../../components/generic-grid/grid-row';
import { TableDate } from './components/scan-table/table-date';
import { FormatScanTotalIssues } from './components/scan-table/format-total-issues';
import styled from 'styled-components';
import { Pill } from '../../components/flowy-components/styled-components';
import TextLink from '../../components/common/TextLink';
import { ScanTableLoader } from './components/scan-table/scan-table-loader';
import { ScanTableActions } from './components/scan-table/scan-table-actions';
import { Scan } from '@equally-ai-front/common/src/types/issues';
import { Box } from '@mui/material';
import moment from 'moment';
import { GroupDataTableScans } from './components/group-data-table-scans';

export const rowMap: FieldMap[] = [
  {
    key: 'type',
    size: {
      sm: 3,
      xs: 3,
      md: 2,
    },
  },
  {
    key: 'last_scan',
    hideOnMobile: true,
    size: {
      sm: 2,
      xs: 2,
      md: 2,
    },
  },
  {
    key: 'total',
    hideOnMobile: true,
    size: {
      sm: 3,
      xs: 3,
      md: 4,
    },
  },
  {
    key: 'actions',
    hideOnMobile: true,
    size: {
      sm: 1,
      xs: 1,
      md: 1,
    },
  },
];

export const groupRowMap: FieldMap[] = [
  {
    key: 'scans',
    hideOnMobile: true,
    size: {
      sm: 2,
      xs: 2,
      md: 2,
    },
  },
  {
    key: 'created_at',
    hideOnMobile: true,
    size: {
      sm: 3,
      xs: 3,
      md: 4,
    },
  },
];

export const groupScanRowMap: FieldMap[] = [
  {
    key: 'scans',
    hideOnMobile: true,
    size: {
      sm: 2,
      xs: 2,
      md: 4,
    },
  },
  {
    key: 'created_at',
    hideOnMobile: true,
    size: {
      sm: 3,
      xs: 3,
      md: 3,
    },
  },
];
const dataMap: FieldMap[] = [
  {
    key: 'type',
    size: {
      sm: 1,
      xs: 4,
      md: 2,
    },
    format: (row, key) => (
      <StyledPill bgColor="#eee" color="#000a14">
        {row[key]}
      </StyledPill>
    ),
  },
  {
    key: 'created_at',
    size: {
      sm: 1,
      xs: 4,
      md: 2,
    },
    format: (row, key, isLoading) => (
      <TableDate
        date={String(row[key])}
        id={+row.id}
        issues={Number(row.warning) + Number(row.notice) + Number(row.error)}
        rowData={{ ...row, url: row.domain || row.url }}
        isLoading={isLoading || false}
      />
    ),
  },
  {
    key: 'total',
    size: {
      sm: 1,
      xs: 7,
      md: 4,
    },
    format: (row, key, isLoading) => {
      return (
        <>
          {isLoading ? (
            <span>-</span>
          ) : (
            <FormatScanTotalIssues
              notice={row.notice}
              warning={row.warning}
              error={row.error}
            />
          )}
        </>
      );
    },
  },
  {
    key: 'actions',
    size: {
      sm: 1,
      xs: 1,
      md: 1,
    },
    format: (row, key, isLoading) => {
      // @ts-ignore
      const domainScan: Scan = row;
      return <>{!isLoading && <ScanTableActions domainScan={domainScan} />}</>;
    },
  },
];

const groupDataMap: FieldMap[] = [
  {
    key: 'scans',
    size: {
      sm: 1,
      xs: 4,
      md: 2,
    },
    format: (row: any) => <GroupDataTableScans scans={row.scans} />,
  },
  {
    key: 'created_at',
    size: {
      sm: 1,
      xs: 4,
      md: 2,
    },
    format: (row, key) => (
      <span> {moment(new Date(row[key])).format('MMMM Do, YYYY')}</span>
    ),
  },
];

export const pageScansDataMap: FieldMap[] = [
  {
    key: 'domain',
    size: {
      sm: 2,
      xs: 6,
      md: 3,
    },
    format: (row, key, isLoading) => {
      const url = row[key] || row.url;
      let linkTo;
      if (row.group_id) {
        linkTo = `../issues/${row.id}`;
      } else {
        linkTo = `../page?page_url=${encodeURIComponent(url)}`;
      }
      return (
        <>
          <StyledTextLink to={linkTo}>{url}</StyledTextLink>
          {isLoading && <ScanTableLoader isLoading={isLoading || false} />}
        </>
      );
    },
  },
  ...dataMap,
];

export const scanHistoryDataMap: FieldMap[] = [
  {
    key: 'url',
    size: {
      sm: 2,
      xs: 6,
      md: 3,
    },
    format: (row, key, isLoading) => {
      return (
        <>
          <StyledScanHistoryTextLink
            to={`page?page_url=${encodeURIComponent(row[key])}`}
            title={row[key]}
          >
            {row[key]}
          </StyledScanHistoryTextLink>
          {isLoading && <ScanTableLoader isLoading={isLoading || false} />}
        </>
      );
    },
  },
  ...dataMap,
];

export const groupScanHistoryDataMap: FieldMap[] = [
  {
    key: 'name',
    size: {
      sm: 2,
      xs: 6,
      md: 3,
    },
    format: (row, key, isLoading) => {
      return (
        <Box display="flex" flexDirection="column">
          <StyledScanHistoryTextLink
            to={`group?group_scan_id=${encodeURIComponent(row.id)}`}
            title={row[key]}
          >
            {row[key] || row.name}
          </StyledScanHistoryTextLink>

          {isLoading && <ScanTableLoader isLoading={isLoading || false} />}
        </Box>
      );
    },
  },
  ...groupDataMap,
];

export const rowData = {
  type: 'Type',
  last_scan: 'Created At',
  total: 'Totals',
  actions: '',
};

export const groupRowData = {
  scans: 'Scans',
  created_at: 'Created At',
};

export const nativeHTMLElementsSelectOptions = [
  { label: 'html', key: 'html' },
  { label: 'head', key: 'head' },
  { label: 'body', key: 'body' },
  { label: 'link', key: 'link' },
  { label: 'meta', key: 'meta' },
  { label: 'title', key: 'title' },
  { label: 'address', key: 'address' },
  { label: 'article', key: 'article' },
  { label: 'aside', key: 'aside' },
  { label: 'footer', key: 'footer' },
  { label: 'header', key: 'header' },
  { label: 'h1', key: 'h1' },
  { label: 'h2', key: 'h2' },
  { label: 'h3', key: 'h3' },
  { label: 'h4', key: 'h4' },
  { label: 'h5', key: 'h5' },
  { label: 'h6', key: 'h6' },
  { label: 'main', key: 'main' },
  { label: 'nav', key: 'nav' },
  { label: 'section', key: 'section' },
  { label: 'blockquote', key: 'blockquote' },
  { label: 'div', key: 'div' },
  { label: 'figcaption', key: 'figcaption' },
  { label: 'figure', key: 'figure' },
  { label: 'hr', key: 'hr' },
  { label: 'li', key: 'li' },
  { label: 'ol', key: 'ol' },
  { label: 'p', key: 'p' },
  { label: 'pre', key: 'pre' },
  { label: 'ul', key: 'ul' },
  { label: 'a', key: 'a' },
  { label: 'abbr', key: 'abbr' },
  { label: 'b', key: 'b' },
  { label: 'br', key: 'br' },
  { label: 'code', key: 'code' },
  { label: 'data', key: 'data' },
  { label: 'em', key: 'em' },
  { label: 'i', key: 'i' },
  { label: 'small', key: 'small' },
  { label: 'span', key: 'span' },
  { label: 'strong', key: 'strong' },
  { label: 'audio', key: 'audio' },
  { label: 'img', key: 'img' },
  { label: 'video', key: 'video' },
  { label: 'embed', key: 'embed' },
  { label: 'iframe', key: 'iframe' },
  { label: 'object', key: 'object' },
  { label: 'picture', key: 'picture' },
  { label: 'source', key: 'source' },
  { label: 'canvas', key: 'canvas' },
  { label: 'caption', key: 'caption' },
  { label: 'col', key: 'col' },
  { label: 'colgroup', key: 'colgroup' },
  { label: 'table', key: 'table' },
  { label: 'tbody', key: 'tbody' },
  { label: 'td', key: 'td' },
  { label: 'tfoot', key: 'tfoot' },
  { label: 'th', key: 'th' },
  { label: 'thead', key: 'thead' },
  { label: 'tr', key: 'tr' },
  { label: 'button', key: 'button' },
  { label: 'datalist', key: 'datalist' },
  { label: 'fieldset', key: 'fieldset' },
  { label: 'form', key: 'form' },
  { label: 'input', key: 'input' },
  { label: 'label', key: 'label' },
  { label: 'legend', key: 'legend' },
  { label: 'optgroup', key: 'optgroup' },
  { label: 'option', key: 'option' },
  { label: 'progress', key: 'progress' },
  { label: 'select', key: 'select' },
  { label: 'textarea', key: 'textarea' },
  { label: 'dialog', key: 'dialog' },
  { label: 'menu', key: 'menu' },
];

export const nativeHTMLElements = [
  'html',
  'head',
  'body',
  'link',
  'meta',
  'title',
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'main',
  'nav',
  'section',
  'blockquote',
  'div',
  'figcaption',
  'figure',
  'hr',
  'li',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'br',
  'code',
  'data',
  'em',
  'i',
  'small',
  'span',
  'strong',
  'audio',
  'img',
  'video',
  'embed',
  'iframe',
  'object',
  'picture',
  'source',
  'canvas',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'button',
  'datalist',
  'fieldset',
  'form',
  'input',
  'label',
  'legend',
  'optgroup',
  'option',
  'progress',
  'select',
  'textarea',
  'dialog',
  'menu',
];

export const getHtmlElementFromString = (htmlString: string) => {
  const regex = /<\s*([a-zA-Z0-9\-]+)\s*/;
  const match = htmlString.match(regex);
  return match ? match[1].toLowerCase() : null;
};

const StyledPill = styled(Pill)`
  font-size: 12px;
  width: fit-content;
  font-weight: 700;
`;

const StyledTextLink = styled(TextLink)`
  border-bottom: none;
  cursor: pointer;
  padding-right: 10px;
  width: fit-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 2px;
    background-color: #6966b2;
  }

  &:hover {
    border-bottom: none;
    padding-bottom: 2px;
  }

  &:hover::after {
    width: calc(100% - 10px);
  }
`;

const StyledScanHistoryTextLink = styled(StyledTextLink)`
  white-space: nowrap;
`;

const StyledGroupScanHistoryTextLink = styled(TextLink)`
  border-bottom: none;
  cursor: pointer;
  overflow: hidden;
  width: fit-content;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
`;
