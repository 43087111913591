import { useMediaQuery } from '@equally-ai-front/common/src/hooks/useMediaQuery';
import { DevDomain } from '@equally-ai-front/common/src/types/domains';
import { NewScanInfo, Scan } from '@equally-ai-front/common/src/types/issues';
import { ReportData } from '@equally-ai-front/common/src/utils/devToolsTypeConfig';
import { OutlinedInput, Tab, Tabs } from '@mui/material';
import { styled as MuiStyled, withStyles } from '@mui/styles';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { PrimaryButton } from '../../../components/redesign/Controls';
import { formatDomainInputUrl } from '../../../utils/domain.utils';
import { EmptyManualScans } from './empty-manual-scans';
import { ScanType } from '@equally-ai-front/common/src/types/scans';
import { RootState } from '../../../store';
import { CreateScanButton } from './create-scan-button';
import { capitalizeFirstLetter } from '@equally-ai-front/common/src/utils/helpers';
import { sortScans } from '@equally-ai-front/common/src/lib/scans';
import { getIssueGroupName } from '@equally-ai-front/common/src/lib';
import { useScroll } from '@equally-ai-front/common/src/contexts/scroll-provider';

interface DashboardSidePanelContentProps {
  onAudit: (url: string, cb?: () => void, scanDetails?: NewScanInfo) => void;
  wcagReport: ReportData;
  devDomain: DevDomain;
  closeDialog?: () => void;
}

interface DashboardTab {
  label: string;
  to: string;
  icon: string;
}

type DashboardTabs = Record<string, DashboardTab>;

const getScanHistoryListItemColor = (
  error: number,
  warning: number,
): string => {
  let color = '#AEEAD3';

  if (error > 0) {
    color = '#FF9999';
  } else if (error === 0 && warning > 0) {
    color = '#f2df87';
  }
  return color;
};

export const DashboardSidePanelContent = (
  props: DashboardSidePanelContentProps,
) => {
  const { onAudit, devDomain, closeDialog } = props;
  const isOnMobile = useMediaQuery('(pointer:none), (pointer:coarse)');
  const { domainId } = useParams();
  const { history, currentBusiness, scannedUrls, visibleWcagReport } =
    useSelector((state: RootState) => ({
      history: state.scanHistory.history,
      currentBusiness: state.business.currentBusiness,
      scannedUrls: state.dashboard.scannedUrls,
      visibleWcagReport: state.scanHistory.visibleWcagReport,
    }));
  const issueHeaderLinksRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const pageUrl = searchParams.get('page_url');
  const groupScanId = searchParams.get('group_scan_id');
  const [showUrlPathnameInput, setShowUrlPathnameInput] = useState(false);
  const [urlPathname, setUrlPathname] = useState('');
  const scanGroups = useSelector((state: any) => state.scanHistory.scanGroups);
  const createScanButtonRef = useRef<HTMLButtonElement | null>(null);
  const handleToggleUrlPathnameInput = () => {
    setShowUrlPathnameInput(!showUrlPathnameInput);
  };
  const { scrollToSection } = useScroll();

  const TABS: DashboardTabs = {
    overview: {
      label: 'Overview',
      to: 'overview',
      icon: '/overview-icon.svg',
    },
    issues: {
      label: 'Issues',
      to: 'scans',
      icon: '/issues.svg',
    },
    actions: {
      label: 'Actions',
      to: 'actions',
      icon: '/actions.svg',
    },
    settings: {
      label: 'Settings',
      to: 'settings',
      icon: '/settings_accessibility.svg',
    },
    complianceManager: {
      label: 'Compliance Manager',
      to: `/${currentBusiness?.slug}/compliance-manager/overview`,
      icon: '/sensor-occupied.svg',
    },
  };

  const handleAddNewScan = () => {
    if (!createScanButtonRef.current) return;
    createScanButtonRef.current.click();
  };

  const handleUrlPathnameChange = (
    evt: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = formatDomainInputUrl(devDomain, evt.target.value);
    setUrlPathname(value);
  };

  const navigateToScanPage = (url: string) => {
    if (isOnMobile && closeDialog) {
      closeDialog();
    }
    navigate(`scans/page?page_url=${encodeURIComponent(url)}`);
  };
  const navigateToGroupPage = (id: number) => {
    if (isOnMobile && closeDialog) {
      closeDialog();
    }
    navigate(`scans/group?group_scan_id=${id}`);
  };

  const getPathName = (scannedUrl: string): string => {
    if (!scannedUrl.includes(devDomain.name)) {
      return capitalizeFirstLetter(scannedUrl);
    }
    return new URL(`https://${scannedUrl}`).pathname.replace(
      new RegExp(`^${devDomain.name}`),
      '',
    );
  };

  const getActiveTab = () => {
    const path = location.pathname.split('/')[5];
    switch (path) {
      case 'overview':
        return 0;
      case 'scans':
        return 1;
      case 'actions':
        return 2;
      case 'settings':
        return 3;
      default:
        return 0;
    }
  };

  const performAudit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    const currentTab = getActiveTab();
    onAudit(urlPathname, () => {
      handleToggleUrlPathnameInput();
      setUrlPathname('');

      if (currentTab !== 0) {
        navigate(
          `/${currentBusiness?.slug}/developers/${devDomain.id}/flowy/scans`,
        );
      }
    });
  };

  const getComponentPageResult = async (componentPage: string) => {
    const scanHistoryData = history.find(
      (historyData) => historyData.type === ScanType.automated,
    );
    if (!scanHistoryData) {
      return;
    }
    navigate(
      `/${currentBusiness?.slug}/developers/${domainId}/flowy/scans/issues?component=${componentPage}`,
      // `scans/page?component=${componentPage}`,
    );
  };

  const filterScanHistoryByType = (type: ScanType): Scan[] => {
    const uniqueScansUrls: Record<string, Scan> = {};

    for (const scan of sortScans([...scannedUrls, ...history])) {
      const newScanDate = new Date(scan.created_at);
      const existingScanDate = new Date(uniqueScansUrls[scan.url]?.created_at);

      if (
        (!uniqueScansUrls[scan.url] && scan.type === type) ||
        newScanDate > existingScanDate
      ) {
        uniqueScansUrls[scan.url] = scan;
      }
    }
    let scans = [];
    const mainPageScan: Scan | undefined = uniqueScansUrls[devDomain.name];
    const footerScan: Scan | undefined = uniqueScansUrls.footer;
    const headerScan: Scan | undefined = uniqueScansUrls.header;

    for (const template of [mainPageScan, headerScan, footerScan]) {
      if (template) {
        scans.push(template);
        delete uniqueScansUrls[template.url];
      }
    }

    if (scans.length > 0) {
      scans = [...scans, ...Object.values(uniqueScansUrls)];
    } else {
      scans = [...Object.values(uniqueScansUrls)];
    }
    return scans;
  };

  useEffect(() => {
    if (visibleWcagReport && visibleWcagReport.reportGroups.all.length > 0) {
      issueHeaderLinksRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [visibleWcagReport]);

  return (
    <Wrapper>
      <Main>
        <CreateScanButtonWrapper>
          <CreateScanButton onAudit={onAudit} devDomain={devDomain} />
        </CreateScanButtonWrapper>
        {/* <SidePanelHeading
          wcagReport={wcagReport}
          onAudit={onAudit}
          devDomain={devDomain}
          closeDialog={closeDialog}
          isOnMobile={isOnMobile}
        /> */}
        <FilterTextDiv value={getActiveTab()}>
          {Object.keys(TABS).map((tabKey, index) => {
            const { label, to, icon } = TABS[tabKey];

            return (
              <CustomTab
                tabIndex={0}
                key={`dashboard-tab-${to}`}
                aria-selected={getActiveTab() === index}
                // @ts-ignore
                component={NavLink}
                to={to}
                label={<TabLabel>{label}</TabLabel>}
                icon={
                  <TabIcon
                    style={{ marginBottom: 0 }}
                    alt={`${label} icon`}
                    src={icon}
                  />
                }
                onClick={closeDialog}
              />
            );
          })}
        </FilterTextDiv>
        {visibleWcagReport && visibleWcagReport.reportGroups.all.length > 0 && (
          <ListContainer
            ref={issueHeaderLinksRef}
            ismobile={isOnMobile ? isOnMobile.toString() : ''}
          >
            <ScanHistoryTitle>Issues Groups</ScanHistoryTitle>
            <CustomUL
              isWebsiteUrlPresent
              ismobile={isOnMobile ? isOnMobile.toString() : ''}
              role="list"
            >
              {visibleWcagReport.reportGroups.all.map(
                ([groupTitle, _], vwridx) => {
                  const issueGroupTitle = getIssueGroupName(groupTitle);

                  return (
                    <CustomLi
                      role="listitem"
                      tabIndex={0}
                      key={`visible-wcag-report-${vwridx}`}
                      ismobile={isOnMobile ? isOnMobile.toString() : ''}
                    >
                      <IssueHeaderLink
                        onClick={() => {
                          scrollToSection(issueGroupTitle);
                        }}
                      >
                        {issueGroupTitle}
                      </IssueHeaderLink>
                    </CustomLi>
                  );
                },
              )}
            </CustomUL>
          </ListContainer>
        )}
        {Object.values(scanGroups).length > 0 && (
          <ListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
            <ScanHistoryTitle>Group</ScanHistoryTitle>
            <CustomUL
              isWebsiteUrlPresent
              ismobile={isOnMobile ? isOnMobile.toString() : ''}
              role="list"
            >
              {Object.values(scanGroups).map((scanGroup: any, sgidx) => {
                const { name, id } = scanGroup;
                return (
                  <CustomLi
                    role="listitem"
                    tabIndex={0}
                    key={`scan-group-${sgidx}`}
                    ismobile={isOnMobile ? isOnMobile.toString() : ''}
                    onClick={() => {
                      navigateToGroupPage(id);
                    }}
                    onKeyDown={(evt) => {
                      if (evt.key === 'Enter' || evt.key === ' ') {
                        navigateToGroupPage(id);
                      }
                    }}
                  >
                    <CustomLiText isActive={id === groupScanId}>
                      {name}
                    </CustomLiText>
                  </CustomLi>
                );
              })}
            </CustomUL>
          </ListContainer>
        )}
        <ListContainer ismobile={isOnMobile ? isOnMobile.toString() : ''}>
          {history.length > 0 &&
            [ScanType.automated, ScanType.manual].map((type) => (
              <React.Fragment key={type}>
                <ScanHistoryTitleWrapper>
                  <ScanHistoryTitle>{type}</ScanHistoryTitle>
                  {type === ScanType.automated && (
                    <>
                      <CustomLiIcon
                        role="button"
                        tabIndex={0}
                        onClick={handleAddNewScan}
                        onKeyDown={(evt) => {
                          if (evt.key === 'Enter' || evt.key === ' ') {
                            handleAddNewScan();
                          }
                        }}
                        src={
                          showUrlPathnameInput
                            ? '/minus-symbol.svg'
                            : '/add-symbol.svg'
                        }
                        alt=""
                      />
                      <ScanButtonPlusIconWrapper>
                        <CreateScanButton
                          onAudit={onAudit}
                          devDomain={devDomain}
                          ref={createScanButtonRef}
                        />
                      </ScanButtonPlusIconWrapper>
                    </>
                  )}
                </ScanHistoryTitleWrapper>
                <CustomUL
                  isWebsiteUrlPresent
                  ismobile={isOnMobile ? isOnMobile.toString() : ''}
                  role="list"
                >
                  {showUrlPathnameInput && type === ScanType.automated && (
                    <CustomLi
                      role="listitem"
                      bulletColor="#808080"
                      ismobile={isOnMobile ? isOnMobile.toString() : ''}
                      editing="true"
                    >
                      <>
                        <UrlInputForm onSubmit={performAudit}>
                          <MuiInput
                            type="text"
                            value={urlPathname}
                            onChange={handleUrlPathnameChange}
                            autoFocus
                          />
                          <ScanButton
                            aria-label="Scan url"
                            color="primary"
                            variant="contained"
                            type="submit"
                          >
                            Scan
                          </ScanButton>
                        </UrlInputForm>
                      </>
                    </CustomLi>
                  )}

                  {filterScanHistoryByType(type).map(
                    ({ url, id, error, warning }: Scan) => {
                      const pathname = getPathName(url);

                      return (
                        <CustomLi
                          role="listitem"
                          tabIndex={0}
                          key={id}
                          bulletColor={getScanHistoryListItemColor(
                            error,
                            warning,
                          )}
                          ismobile={isOnMobile ? isOnMobile.toString() : ''}
                          onClick={() => {
                            navigateToScanPage(url);
                          }}
                          onKeyDown={(evt) => {
                            if (evt.key === 'Enter' || evt.key === ' ') {
                              navigateToScanPage(url);
                            }
                          }}
                        >
                          <CustomLiText isActive={url === pageUrl}>
                            {pathname === '/' ? 'Home' : pathname}
                          </CustomLiText>
                        </CustomLi>
                      );
                    },
                  )}
                </CustomUL>
              </React.Fragment>
            ))}
        </ListContainer>
        {history.length > 0 &&
          filterScanHistoryByType(ScanType.manual).length === 0 && (
            <EmptyManualScans />
          )}
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Main = styled.main``;

type StyleComponentProps = { ismobile: string };

const ListContainer = styled.div<StyleComponentProps>`
  overflow-y: auto;
  height: ${(p) => (p.ismobile ? '250px' : '100%')};
  width: 90%;
  margin: auto;
  margin-top: ${(p) => (p.ismobile ? '15px' : '10px')};

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ffffff;
    border-radius: 10px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6; /* 121212 */
    border-radius: 10px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b0acae;
  }
`;

type CustomUlProps = { isWebsiteUrlPresent: boolean; ismobile: string };

const CustomUL = styled.ul<CustomUlProps>`
  margin-top: ${(p) => (p.isWebsiteUrlPresent ? '' : '20%')};
  margin-left: ${(p) => (p.ismobile ? '62px' : '23px')};
  padding: 0;
`;

type CustomLiProps = {
  bulletColor?: string;
  ismobile: string;
  editing?: string;
};

const CustomLi = styled.li<CustomLiProps>`
  margin-bottom: ${(p) => (p.ismobile ? '10px' : '5px')};
  cursor: pointer;
  width: ${(p) => (p.editing === 'true' ? '100%' : 'fit-content')};
  min-width: 20px;
  list-style: outside;

  &::marker {
    color: ${(p) => p.bulletColor};
    font-size: 25px;
  }

  &:last-child {
    margin-bottom: 30px;
  }
`;

type CustomLiTextProps = {
  isActive: boolean;
};

const CustomLiText = styled.span<CustomLiTextProps>`
  color: #000a14;
  word-wrap: break-word;
  ${(p) => p.isActive && 'font-weight: 600'};
  font-size: 15px;
  vertical-align: text-bottom;
  margin-left: -3px;
`;

const IssueHeaderLink = styled.span`
  color: #000a14;
  word-wrap: break-word;
  font-size: 15px;
  vertical-align: text-bottom;
  margin-left: -3px;
`;

const ScanHistoryTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CustomLiIcon = styled.img`
  cursor: pointer;
`;

const ScanHistoryTitle = styled.p`
  font-weight: 500;
  font-size: 13px;
  background-color: #ffffff;
  color: #50565f;
  border-radius: 8px;
  padding: 5px 8px;
  width: fit-content;
`;

const FilterTextDiv = withStyles({
  root: {
    marginBottom: '10%',
    borderTop: '2px solid #e8e8e8',

    '& .MuiTabs-flexContainer': {
      flexDirection: 'column',

      '& .MuiTab-root': {
        maxWidth: '100%',
        borderBottom: '2px solid #e8e8e8',
        paddingLeft: '6%',

        '& .MuiTab-wrapper': {
          fontSize: 13,
          '& > *:first-of-type': {
            marginBottom: 0,
          },
        },

        '&.Mui-selected': {
          borderBottom: '2px solid #1890ff',
        },
      },
    },
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const TabLabel = styled.span``;

const TabIcon = styled.img`
  margin-right: 15px;
`;

const MuiInput = MuiStyled(OutlinedInput)({
  '&.MuiOutlinedInput-root': {
    width: '100%',
    maxWidth: 'calc(100% - 70px)',
  },
  '&.MuiOutlinedInput-root input': {
    backgroundColor: 'transparent',
    padding: 0,
    fontSize: 15,
  },
  '& fieldset': {
    border: 'none',
  },
  '&:hover fieldset': {
    border: 'none',
  },
});

const UrlInputForm = styled.form`
  display: flex;
  justify-content: space-between;
`;

const ScanButton = styled(PrimaryButton)`
  height: 32px;
  width: 64px;
`;

const CreateScanButtonWrapper = styled.div`
  margin: 15px 0;
`;

const ScanButtonPlusIconWrapper = styled.div`
  display: none;
`;

const CustomTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
